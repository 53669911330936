
export default {
  layout: 'blankPage',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    message () {
      if (this.error.message === 'This page could not be found') {
        return 'We kunnen de pagina die je zoekt niet vinden.';
      }

      return this.error.message;
    },

    subtext () {
      if (this.error.statusCode === 401) {
        return 'Klopt dit niet? Neem contact op met uw organisatie-administrator.';
      }

      return 'Hier zijn een paar links die nuttig kunnen zijn.';
    }
  }
};
