import { render, staticRenderFns } from "./blankPage.vue?vue&type=template&id=752d2216&scoped=true"
import script from "./blankPage.vue?vue&type=script&lang=js"
export * from "./blankPage.vue?vue&type=script&lang=js"
import style0 from "./blankPage.vue?vue&type=style&index=0&id=752d2216&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752d2216",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/app/components/Navbar.vue').default,Modal: require('/app/components/modal.vue').default,AlturaLogo: require('/app/node_modules/@altura/components/components/Logo.vue').default})
