import Filter from './Filter';

export default class SellingOrganizationFilter extends Filter {
  get name () {
    return 'sellingOrganization';
  }

  get label () {
    return 'Leveranciers';
  }

  get UI () {
    return 'sellingOrganization';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  count (options) {
    if (options.length > 0) { return options.length; }
  }

  isActive (options) {
    return options.length > 0;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = searchprofile.sellingOrganizations?.length > 0;
    filter.options = searchprofile.sellingOrganizations?.map(x => x.id) ?? [];
  }

  toSearchProfile (sellingOrganizations) {
    return {
      sellingOrganizations: sellingOrganizations.map(x => ({ id: x }))
    };
  }
}
