export const state = () => ({
  show: false,
  title: '',
  body: '',
  compact: false,
  destructive: false,
  positiveButton: {
    label: '',
    action: () => { }
  },
  negativeButton: {
    label: '',
    action: () => { }
  }
});

export const mutations = {
  showModal (state, { title, body, positiveButton, negativeButton, compact, destructive }) {
    state.title = title;
    state.body = body;
    state.positiveButton = positiveButton;
    state.negativeButton = negativeButton;
    state.show = true;
    state.compact = !!compact;
    state.destructive = !!destructive;
  },

  closeModal (state) {
    state.show = false;
    state.compact = false;
    state.destructive = false;

    state.title = '';
    state.body = '';
    state.positiveButton = {
      label: '',
      action: () => { }
    };

    state.negativeButton = {
      label: '',
      action: () => { }
    };
  }
};
