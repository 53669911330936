import Filter from './Filter';

export default class ProposalEvaluationTagFilter extends Filter {
  get name () {
    return 'proposal-evaluation-Tag';
  }

  get label () {
    return 'Evaluatie tags';
  }

  get UI () {
    return { name: 'proposal-evaluation-Tag', resizable: true };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = {
      ids: [],
      includeUnknown: true
    };
  }

  count (options) {
    if (options.ids.length > 0) { return options.ids.length; }
  }

  isActive (options) {
    return options.ids.length > 0 || !options.includeUnknown;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.evaluationTag) {
      filter.options = {
        ids: searchprofile.evaluationTag.ids,
        includeUnknown: searchprofile.evaluationTag.includeUnknown
      };
      filter.active = this.isActive(filter.options);
    }
  }

  toSearchProfile (options) {
    if (this.isActive(options)) {
      return {
        evaluationTag: {
          ids: options.ids,
          includeUnknown: options.includeUnknown
        }
      };
    } else {
      return {};
    }
  }

  fromData (options) {
    const data = super.fromData(options);
    return {
      ids: data.ids,
      includeUnknown: data.includeUnknown === 'true'
    };
  }
}
