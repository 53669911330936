import Filter from './Filter';

export default class ProposalIncludeSuggestionFilter extends Filter {
  get name () {
    return 'proposal-include-suggestion';
  }

  get label () {
    return 'Suggesties';
  }

  get UI () {
    return 'proposal-include-suggestion';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = false;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = !!searchprofile.include_suggestion;
    filter.options = searchprofile.include_suggestion;
  }

  toSearchProfile (options) {
    return {
      include_suggestion: options
    };
  }
}
