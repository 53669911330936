import Filter from './Filter';

const STOPPED_PROCEDURE_ENUM = ['None', 'WithoutStoppedProcedure', 'WithProcedure'];

export default class StoppedProcedureFilter extends Filter {
  get name () {
    return 'stoppedProcedure';
  }

  get label () {
    return 'Gestopte procedures';
  }

  get UI () {
    return 'stoppedProcedure';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = ['WithoutStoppedProcedure', 'WithProcedure'];
  }

  count (options) {
    if (options.length !== 3) { return options.length; }
  }

  isActive (options) {
    return options.length !== 3;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = searchprofile.stoppedProcedure === 0 || searchprofile.stoppedProcedure === 'None';
    filter.options = STOPPED_PROCEDURE_ENUM[searchprofile.stoppedProcedure];
  }

  toSearchProfile (options) {
    return {
      stoppedProcedure: STOPPED_PROCEDURE_ENUM.indexOf(options)
    };
  }
}
