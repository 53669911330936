export const state = () => ({
  pageBlocked: false,
  feature: ''
});

export const mutations = {
  showModal (state, { blockPage, feature }) {
    state.pageBlocked = !!blockPage;
    state.feature = feature;
  },

  closeModal (state) {
    state.feature = '';
    state.pageBlocked = false;
  }
};
