import createFilters from '~/mixins/FilterStore';

const filters = createFilters('municipalBudgetsOverview', ['municipal', 'year']);
export const state = () => ({
  ...filters.state,
  municipalBudgets: [],
  count: 0,
  page: 3,
  maxPages: 1,
  category: 'all',
  sort: undefined,
  loading: false,
  time: 0,
  pdf: {
    url: '',
    name: '',
    highlight: '',
    open: false
  }
});

export const mutations = {
  ...filters.mutations,
  setTime (state, time) {
    state.time = time;
  },

  setSort (state, sort) {
    const value = state.sort;
    state.sort = sort;
    if (value !== sort) { this.dispatch('municipalBudgetsOverview/fetch', true); }
  },

  setCategory (state, category) {
    const value = state.category;
    state.category = category;
    if (value !== category) { this.dispatch('municipalBudgetsOverview/fetch', true); }
  },

  setSearchText (state, searchText) {
    state.searchText = searchText;
    this.dispatch('municipalBudgetsOverview/fetch', true);
  },

  clearMunicipalBudgets (state) {
    state.page = 0;
    state.municipalBudgets = [];
    state.count = 0;
  },

  setPage (state, number) {
    if (number === undefined) {
      state.page++;
      return;
    }

    state.page = number;
  },

  setMaxPage (state, number) {
    state.maxPages = number;
  },

  setMunicipalBudgets (state, { municipalBudgets, count }) {
    state.municipalBudgets = municipalBudgets;

    if (count) {
      state.maxPages = Math.ceil(count / 25);
      state.count = count;
    }
  },

  setLoading (state, loading) {
    state.loading = loading;
  },

  reset (state) {
    this.commit('municipalBudgetsOverview/resetFilters');
    state.category = '';
    this.dispatch('municipalBudgetsOverview/fetch', true);
  },

  setPDF (state, pdf) {
    state.pdf = pdf;
  },

  resetPDF (state) {
    state.pdf = {
      url: '',
      name: '',
      highlight: '',
      open: false
    };
  },

  closePDF (state) {
    state.pdf = {
      url: '',
      name: '',
      highlight: '',
      open: false
    };
  }
};

export const getters = {
  ...filters.getters
};

export const actions = {
  ...filters.actions,

  async fetch ({ state, commit, getters }, force) {
    if ((state.municipalBudgets.length > 0 && !force) || state.loading) { return; }

    const time = Date.now();

    commit('clearMunicipalBudgets');
    commit('setLoading', true);

    const params = new URLSearchParams();

    if (state.sort) { params.set('sortingType', state.sort); }
    if (state.search) { params.set('searchQuery', state.search); }

    if (state.category) { params.set('category', state.category); }

    params.set('filtered', true);

    try {
      const body = await this.$axios.$post(`municipalbudget/list?${params.toString()}`, getters.apiFilters);

      const municipalBudgets = body.municipalBudgets;

      commit('setTime', Date.now() - time);
      commit('setMunicipalBudgets', { municipalBudgets, count: body.totalAmount });
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },

  async fetchNextPage ({ state, commit, getters }) {
    if (state.loading || state.page >= state.maxPages || state.maxPages === 1) { return; }
    commit('setLoading', true);
    const params = new URLSearchParams();

    if (state.sort) { params.set('sortingType', state.sort); }
    if (state.search) { params.set('searchQuery', state.search); }

    if (state.category) { params.set('category', state.category); }

    params.set('filtered', true);
    params.set('page', state.page + 1);

    try {
      const body = await this.$axios.$post(`municipalbudget/list?${params.toString()}`, getters.apiFilters);

      const municipalBudgets = [...state.municipalBudgets, ...body.municipalBudgets];

      commit('setLoading', false);
      commit('setPage');
      commit('setMunicipalBudgets', { municipalBudgets });
    } catch (error) {
      console.error(error);
      commit('setLoading', false);
      commit('setMaxPage', state.page);
    }
  }
};
