import Filter from './Filter';

export default class MunicipalFilter extends Filter {
  get name () {
    return 'municipal';
  }

  get label () {
    return 'Provincie/Gemeente';
  }

  get UI () {
    return { name: 'NUTS', resizable: true, options: { rootNode: 'nl' } };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.nutsLocations && searchprofile.nutsLocations.length > 0) {
      filter.options = searchprofile.nutsLocations.map(x => x.nutsCode);
      filter.active = true;
    }
  }

  toSearchProfile (options) {
    if (options.length > 0) {
      return {
        nutsLocations: options.map(x => ({ nutsCode: x }))
      };
    }
  }
}
