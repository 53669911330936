import Filter from './Filter';

export default class SectorFilter extends Filter {
  get name () {
    return 'sector';
  }

  get label () {
    return 'NACE codes';
  }

  get UI () {
    return { name: 'sector', resizable: true };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = {
      codes: [],
      mode: 'Tree'
    };
  }

  count (options) {
    return options.codes.length;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.naces && searchprofile.naces.length > 0) {
      filter.options.codes = {
        codes: searchprofile.naces.map(x => x.code),
        mode: searchprofile.naceMode
      };

      filter.active = true;
    }
  }

  toSearchProfile (options) {
    return {
      naces: options.codes.map(x => ({ code: x })),
      naceMode: options.mode
    };
  }
}
