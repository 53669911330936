import Vue from 'vue';
import qs from 'qs';
import { inflate } from 'pako';
import SearchProfile from '~/filters/SearchProfile';
import { getFilter } from '~/filters';

export function apiFilters (state) {
  let apiFilters = {};

  for (const filter of state.filters) {
    if (!filter) {
      throw new Error('Could not find filter' + filter + state.filters.map(x => JSON.stringify(x ?? { type: 'undefined' })).join(',\n'));
    }
    const options = getFilter(filter.name).toSearchProfile(filter.options);

    if (!options) {
      continue;
    }

    apiFilters = {
      ...apiFilters,
      ...options
    };
  }

  return apiFilters;
}

export default function createFilters (storeName, filters, options) {
  return {
    state: {
      filters: filters.map(x => getFilter(x).getOptions()),
      searchProfile: undefined,
      generatedFilters: [],
      search: '',

      data: options?.default ?? ({ data: [], total: 0 }),

      loading: false
    },

    mutations: {
      setSearch (state, search) {
        state.search = search;

        if (`${storeName}/fetch` in this._actions) {
          this.dispatch(`${storeName}/fetch`, true);
        }
      },
      setLoading (state, loading) {
        state.loading = loading;
      },

      setData (state, data) {
        state.data = data;
      },

      resetFilters (state) {
        state.searchProfile = undefined;
        state.generatedFilters = undefined;
        state.filters = state.filters.map(x =>
          getFilter(x.name).getOptions()
        );
        state.search = '';

        state.data = options?.default ?? ({ data: [], total: 0 });

        if (`${storeName}/fetch` in this._actions) {
          this.dispatch(`${storeName}/fetch`, true);
        }
      },

      setFilter (state, { name, options, fetch = true }) {
        const filter = state.filters.find(x => x.name === name);
        const filterClass = getFilter(name);

        if (!filter) {
          return;
        }

        const index = state.filters.indexOf(filter);

        const active = filterClass.isActive(options);

        // #region hooks
        if (active !== filter.active) {
          if (active) {
            filterClass.onEnable.call(this, storeName, options);
          } else {
            filterClass.onDisable.call(this, storeName, options);
          }
        }

        if (JSON.stringify(filter.options) !== JSON.stringify(options)) {
          filterClass.onUpdate.call(this, storeName, options);
        }
        // #endregion

        filter.options = options;
        filter.active = active;
        Vue.set(state.filters, index, filter);

        if (filterClass.updateResults && fetch) {
          if (`${storeName}/fetch` in this._actions) {
            this.dispatch(`${storeName}/fetch`, true);
          }
        }
      },

      setSearchProfile (state, searchprofile) {
        this.commit(`${storeName}/resetFilters`);
        state.searchProfile = searchprofile;

        if (!searchprofile) {
          if (storeName === 'overview') {
            this.$cookies.remove('searchprofile');
          }

          state.generatedFilters = null;

          return;
        }

        if (storeName === 'overview') {
          this.$cookies.set('searchprofile', searchprofile.id);
        }

        for (const filter of state.filters) {
          getFilter(filter.name).updateFromSearchProfile(searchprofile, filter);
        }

        state.generatedFilters = apiFilters(state);
      }
    },

    getters: {
      apiFilters,

      changes (state, getters) {
        if (state.searchProfile) {
          return JSON.stringify(state.generatedFilters) !== JSON.stringify(getters.apiFilters);
        }

        return state.filters.some(x => x.active);
      },

      url (state, getters) {
        if (state.searchProfile && !getters.changes) {
          return `searchProfileId=${state.searchProfile.id}`;
        }

        const sp = new SearchProfile('filters', state.filters);

        return sp.toQueryString(state.search, true);
      },

      apiCall (state, getters) {
        const parameters = { ...getters.parameters, filtered: true };

        if (state.search) {
          parameters.searchQuery = state.search;
        }

        return {
          body: { ...getters.apiFilters, ...getters.body },
          parameters
        };
      }
    },

    actions: {
      async loadFromUrl (state, path, wait) {
        const url = new URL('https://platform.altura.io' + path);
        let querystring = url.search;

        if (querystring.startsWith('?sp=')) {
          querystring = inflate(Buffer.from(url.searchParams.get('sp'), 'base64'), { to: 'string' });
        }

        const data = qs.parse(querystring, {
          ignoreQueryPrefix: true,
          allowDots: true,
          arrayLimit: 250
        });
        let hasChanges = false;

        if (data.q ?? data.search) {
          hasChanges = true;
          this.commit(`${storeName}/setSearch`, data.q ?? data.search);
          delete data.search;
        }

        if (data.page) {
          hasChanges = true;
          this.commit(`${storeName}/setPage`, Number(data.page));
          this.commit(`${storeName}/setMaxPage`, Number(data.page) + 1);

          return {
            data,
            hasChanges
          };
        }

        if (data.searchProfileId) {
          const [searchProfile] = await this.$axios.$get(
            `/searchprofile/?id=${data.searchProfileId}`
          );

          this.commit(`${storeName}/setSearchProfile`, searchProfile);
          if (!wait) { await this.dispatch(`${storeName}/fetch`, true); } else { hasChanges = true; }

          return {
            data,
            hasChanges
          };
        }

        for (const [name, filterData] of Object.entries(data)) {
          // try catch to ignore query parameters that are not filters
          try {
            const options = getFilter(name).fromData(filterData);

            this.commit(`${storeName}/setFilter`, {
              name,
              options,
              fetch: false
            });
            hasChanges = true;
          } catch {}
        }
        if (hasChanges && !wait) {
          if (`${storeName}/fetch` in this._actions) {
            await this.dispatch(`${storeName}/fetch`, true);
          }
        }

        return {
          data,
          hasChanges
        };
      }
    }

  };
}

/**
 * @param {string} store the store to retrieve the filters from.
 * @param {(body: Record<string, any>, parameters: Record<string, any>)=>({ total: number, [key:string]: any })} hook
*/
export const useApiCall = (store, hook, disableAutoFetch) => {
  return {
    computed: {
      apiCall () {
        return this.$store.getters[`${store}/apiCall`];
      },

      data () {
        return this.$store.state[store].data;
      },

      loading () {
        return this.$store.state[store].loading;
      }
    },

    watch: {
      apiCall: {
        deep: true,
        handler () {
          if (disableAutoFetch) { return; }

          this.fetchData('filter-change');
        }
      }
    },

    created () {
      if (this.data.total === 0) { this.fetchData('initial-call'); }
    },

    mounted () {
      if (this.data.total === 0) { this.fetchData('initial-call'); }
    },

    data () {
      return {
        cancelToken: null
      };
    },

    methods: {
      fetchData: async function (event = 'request') {
        this.$store.commit(`${store}/setLoading`, true);

        if (['request', 'filter-change'].includes(event)) {
          this.$store.commit(`${store}/setData`, { data: [], total: 0 });
        }

        this.cancelToken?.cancel?.();

        const timing = Date.now();

        try {
          this.cancelToken = this.$axios.CancelToken.source();
          const data = await hook.call(this, {
            data: this.apiCall.body,
            params: this.apiCall.parameters,
            cancelToken: this.cancelToken.token
          }, event);
          this.cancelToken = null;

          this.$store.commit(`${store}/setData`, data ?? this.data);
        } catch (error) {
          if (this.$axios.isCancel(error)) {
            return;
          }

          console.error(error);
        }

        this.$store.commit(`${store}/setTime`, Date.now() - timing);

        await this.$nextTick();
        this.$store.commit(`${store}/setLoading`, false);
      }
    }
  };
};
