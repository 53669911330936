
import { mapState, mapMutations } from 'vuex';
import LottieAnimation from 'lottie-web-vue';
import paywallTexts from '~/assets/paywallText.json';
import allDetailsByTender from '~/assets/lottie/all-details-by-tender.json';
import everythingInOnePlace from '~/assets/lottie/everything-in-one-place.json';

export default {
  name: 'ProModal',

  components: {
    LottieAnimation
  },

  computed: {
    ...mapState('proModal', ['feature', 'pageBlocked']),
    paywallTexts () {
      return paywallTexts;
    },
    text () {
      if (!this.feature || !(this.feature in paywallTexts)) {
        console.warn(`Could not find text for feature ${this.feature}`);
        return {};
      }

      return paywallTexts[this.feature];
    },

    show () {
      return !!this.feature;
    },

    animation () {
      if (this.text.animation === 'everything-in-one-place') {
        return everythingInOnePlace;
      } else if (this.text.animation === 'all-details-by-tender') { return allDetailsByTender; }
      return undefined;
    }
  },

  methods: {
    ...mapMutations('proModal', ['closeModal']),

    goToProfileSubscription () {
      this.closeModal();
      this.$router.push('/settings/subscription/');
    },

    async close () {
      const pageBlocked = this.pageBlocked;

      this.closeModal();

      await this.$nextTick();

      if (pageBlocked) { this.$router.replace('/'); }
    }
  }
};
