import Filter from './Filter';

export default class ProposalPerformanceFilter extends Filter {
  get name () {
    return 'proposal-performance';
  }

  get label () {
    return 'Prestatie';
  }

  get UI () {
    return { name: 'proposal-performance' };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = {
      from: undefined,
      to: undefined,
      pending: true
    };
  }

  isActive (options) {
    return options.from !== undefined || options.to !== undefined || !options.pending;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.performance) {
      filter.options = {
        from: searchprofile.performance.from,
        to: searchprofile.performance.to,
        pending: searchprofile.performance.pending
      };
      filter.active = this.isActive(filter.options);
    }
  }

  toSearchProfile (options) {
    if (this.isActive(options)) {
      return {
        performance: {
          from: options.from,
          to: options.to,
          pending: options.pending
        }
      };
    } else {
      return {};
    }
  }

  fromData (options) {
    const data = super.fromData(options);
    return {
      from: data.from,
      to: data.to,
      pending: data.pending === 'true'
    };
  }
}
