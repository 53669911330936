/* eslint-disable no-undef */
import randomColor from '@altura/components/helpers/RandomColor.js';
import egg from '@/assets/holidays/easter/egg.svg?raw';
async function startEggHunt () {
  const startButton = document.querySelector('.egg.start');

  if (startButton) { startButton.style.opacity = 0; }

  const eggs = Math.floor(Math.random() * 16 + 8);
  let eggsCracked = 0;

  const eggCounterDisplay = document.createElement('div');
  eggCounterDisplay.className = 'egg-counter';
  eggCounterDisplay.innerHTML = `
  ${egg}
  <div>
    <h2>Zoek alle paaseieren op deze pagina!</h2><p>${eggsCracked}/${eggs}</p>
  </div>`;

  const counter = eggCounterDisplay.querySelector('p');
  document.body.appendChild(eggCounterDisplay);

  const eggContainer = document.body.appendChild(document.createElement('div'));
  eggContainer.className = 'egg-container';

  for (let i = eggs; i > 0; i--) {
    const eggElement = document.createElement('div');
    eggElement.innerHTML = egg;
    eggElement.className = 'egg';
    eggElement.style.top = Math.floor(Math.random() * 100) + '%';
    eggElement.style.left = Math.floor(Math.random() * 100) + '%';
    eggElement.style.setProperty('--scale', Math.floor(Math.random() * 5 + 2));
    eggElement.style.setProperty('--rotation', `${Math.floor(Math.random() * 360)}deg`);
    eggElement.style.setProperty('--delay', `${Math.floor(Math.random() * 1500)}ms`);
    eggElement.style.fill = randomColor(Math.random().toString());
    eggElement.style.color = randomColor(Math.random().toString());

    eggElement.addEventListener('click', () => {
      eggElement.classList.add('crack');

      eggElement.addEventListener('animationend', () => {
        eggsCracked++;
        counter.innerHTML = `${eggsCracked}/${eggs}`;
        eggElement.remove();

        if (eggsCracked >= eggs) {
          const interval = setInterval(() => {
            window.popper.addConfetti({
              emojis: ['🥚', '🐇', '🔍', '🍫', '🎉', '🎊​'],
              emojiSize: 25
            });
            window.popper.addConfetti();
          }, 500);
          setTimeout(() => clearInterval(interval), 2000);
          eggCounterDisplay.remove();
          eggContainer.remove();
          if (startButton) { startButton.style.opacity = 1; }
        }
      });
    });

    eggContainer.appendChild(eggElement);
  }
}

window.startConfetti = async () => {
  if (!window.popper) {
    await new Promise((resolve) => {
      const confettiPopper = document.createElement('script');
      confettiPopper.onload = resolve;
      confettiPopper.src = 'https://cdn.jsdelivr.net/npm/js-confetti@latest/dist/js-confetti.browser.js';
      document.head.appendChild(confettiPopper);
    });

    window.popper = new JSConfetti();
  }

  window.popper.addConfetti({
    confettiColors: [
      '#4285f4', '#f04d40', '#0d9d58', '#b8ce2e', '#25c3d7', '#f5b400', '#ff7042', '#ab47bc', '#5d6bc0', '#f06292'
    ]
  });
};
window.startEggHunt = startEggHunt;
