import Filter from './Filter';
const stores = {
  overview: 'tenderSearchWords',
  expectedOverview: 'expectedTenderSearchWords',
  municipalBudgetsOverview: 'municipalBudgetsSearchWords'
};
export default class DocumentScannerFilter extends Filter {
  get name () {
    return 'scanner';
  }

  get label () {
    return 'Document scanner';
  }

  get UI () {
    return 'keywords';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  count (options) {
    return options.length;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.documentKeyWords && searchprofile.documentKeyWords.length > 0) {
      filter.options = searchprofile.documentKeyWords;
      filter.active = true;
    }
  }

  toSearchProfile (documentKeyWords) {
    return {
      documentKeyWords
    };
  }

  onDisable (store) {
    this.commit(`${stores[store]}/clear`);
  }

  onUpdate (store, options) {
    this.commit(`${stores[store]}/requeue`);
  }

  fromData (options) {
    const words = super.fromData(options);

    return words.map(x => ({ word: x.word, filter: x.filter === 'true' }));
  }
}
