
import Text from '~/app/textTransformer.js';

export default {
  inject: ['holiday', 'holidayLogo'],

  props: {
    disableMobile: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      currentDropdown: undefined,
      mobile: false
    };
  },

  computed: {
    stack () {
      return this.$store.state.mobileNavigation.stack;
    },

    currentState () {
      if (this.stack.length === 0) {
        return '';
      }

      return this.stack[this.stack.length - 1].name;
    },

    items () {
      if (!this.$auth.loggedIn || this.$user.platform === 'Sero') {
        return [
          {
            name: 'Signaleren',
            url: '/',
            children: [
              {
                name: 'Aanbestedingen',
                url: '/'
              },
              {
                name: 'Gemeentelijke budgetten',
                url: '/municipal-budgets',
                feature: 'municipal-budgets'
              },
              {
                name: 'Aanbestedende diensten',
                url: '/organizations/buyers',
                feature: 'buyers'
              },
              {
                name: 'Inschrijvende partijen',
                url: '/organizations/sellers',
                feature: 'sellers'
              }
            ]
          },
          {
            name: 'Projecten',
            children: [
              {
                name: 'Mijn projecten',
                url: '/collaborate/my-tenders',
                feature: 'my-tenders'
              },
              {
                name: 'Documentenkluis',
                url: '/collaborate/document-vault/',
                feature: 'document-vault'
              },
              {
                name: 'Bid/No-bid',
                url: '/collaborate/bid-no-bid',
                feature: 'bid-no-bid'
              },
              {
                name: 'Proposal search',
                url: '/proposal-search',
                feature: 'proposal-search'
              }
            ]
          },
          {
            name: 'Analyseren',
            children: [
              {
                name: 'Marktinzichten',
                url: '/analyse/market-insights',
                feature: 'market-insights'
              },
              {
                name: 'Marktoverzicht',
                url: '/market-overview/market',
                feature: 'messis'
              }
            ]
          }
        ];
      }

      if (this.$user.platform === 'Messis') {
        return [
          {
            name: 'Inspiratie',
            url: '/',
            children: [
              {
                name: 'Aanbestedingen',
                url: '/'
              },
              {
                name: 'Aanbestedende diensten',
                url: '/organizations/buyers',
                feature: 'buyers'
              },
              {
                name: 'Inschrijvende partijen',
                url: '/organizations/sellers',
                feature: 'sellers'
              }
            ]
          },
          {
            name: 'Marktoverzicht',
            url: '/market-overview/market'
          },
          {
            name: 'Inkoopprofielen',
            url: '/analyse/market-insights',
            feature: 'market-insights'
          }
        ];
      }

      return [];
    }
  },

  mounted () {
    const query = window.matchMedia('only screen and (max-width: 800px)');

    this.mobile = query.matches;

    query.addEventListener('change', () => {
      this.mobile = query.matches;
    });
  },

  methods: {
    back () {
      this.$store.commit('mobileNavigation/back');
    },

    toggleDropdown (name) {
      if (this.currentDropdown === name) {
        this.currentDropdown = undefined;
        return;
      }

      this.currentDropdown = name;
    },

    openTab (url) {
      window.open(url, '_blank');
    },

    go (item, close) {
      if (
        item.feature &&
        !this.$user.hasFeature(Text.toPascalCase(item.feature))
      ) {
        close();
        return;
      }

      this.$router.push(item.url);
      close();
    }
  }
};
