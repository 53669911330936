import Vue from 'vue';
export const state = () => ({
  teamMates: {}
});

export const mutations = {
  setTeamMates (state, { teamId, users }) {
    state.teamMates[teamId] = users;
    Vue.set(state.teamMates, teamId, users);
  }
};

export const actions = {
  async loadTeamMates ({ commit, state }) {
    if (!this.$auth || !this.state.auth.loggedIn) { return; }

    if (this.$user.team.id in state.teamMates && state.teamMates[this.$user.team.id].length > 0) {
      return;
    }

    commit('setTeamMates', { users: [], teamId: this.$user.team.id });

    const users = await this.$axios.$get('/team/users/');

    commit('setTeamMates', { users, teamId: this.$user.team.id });

    return users;
  }
};
