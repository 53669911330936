import createFilters from '@/mixins/FilterStore';

const filters = createFilters('overview', [
  'status',
  'CPV',
  'NUTS',
  'scanner',
  'value',
  'advanced',
  'phase',
  'searchWords',
  'procedure',
  'tenderType',
  'buyingOrganization',
  'sellingOrganization',
  'tenderSource',
  'awardCriteria',
  'publicationDate',
  'endDate',
  'stoppedProcedure',
  'advisingOrganization',
  'firstExpirationDate'
], { default: { tenders: [], total: 0 } });

export const state = () => ({
  ...filters.state,
  tenders: [],
  count: 0,
  page: 1,
  maxPages: 1,
  time: 0,
  category: [],
  sort: undefined,
  loading: false,
  scrapedAt: {}
});

export const mutations = {
  ...filters.mutations,
  setTime (state, time) {
    state.time = time;
  },
  setScrapedAt (state, date) {
    state.scrapedAt = date;
  },

  setSort (state, sort) {
    state.sort = sort;
  },

  setCategory (state, { category, value }) {
    const index = state.category.indexOf(category);
    if (index >= 0) {
      state.category = state.category.filter(x => x !== category);
    } else {
      state.category.push(category);
    }
  },

  clearTenders (state) {
    state.page = 0;
    state.tenders = [];
    state.count = 0;

    this.commit('tenderSearchWords/clear');
  },

  setPage (state, number) {
    if (number === undefined) {
      state.page++;
      return;
    }

    state.page = number;
  },

  setMaxPage (state, number) {
    state.maxPages = number;
  },

  setTenders (state, { tenders, count }) {
    state.tenders = tenders;

    if (count !== undefined) {
      if (count === 0) {
        state.maxPages = 1;
        state.count = 0;
        return;
      }

      state.maxPages = Math.ceil(count / 25);
      state.count = count;
    }
  },

  setLoading (state, loading) {
    state.loading = loading;
  },

  reset (state, fetch = true) {
    this.commit('overview/clearTenders');
    this.commit('overview/resetFilters');
    state.scrapedAt = {};
    state.category = [];
  }
};

export const getters = {
  ...filters.getters,

  parameters (state) {
    const params = {};

    if (state.category.length > 0) {
      params.category = state.category.join();
    }

    if (state.sort) {
      params.sortingType = state.sort;
    }

    return params;
  },

  body (state) {
    return { ...state.scrapedAt };
  }
};

export const actions = {
  ...filters.actions,
  async loadFromUrl (state, path) {
    const { data } = await filters.actions.loadFromUrl.call(
      this,
      state,
      path,
      true
    );

    if (data.minScrapedAtDate && data.maxScrapedAtDate) {
      this.commit('overview/setScrapedAt', {
        minScrapedAtDate: data.minScrapedAtDate,
        maxScrapedAtDate: data.maxScrapedAtDate,
        isHistorical: data.isHistorical === 'true'
      });
    }
  },
  async loadSearchProfile ({ commit, dispatch, state }, id) {
    if (
      state.filters.some(x => x.active) ||
      !this.$cookies.get('searchprofile')
    ) {
      return;
    }

    try {
      const [searchProfile] = await this.$axios.$get(`/searchprofile?id=${id}`);
      commit('setSearchProfile', searchProfile);
    } catch (e) {
      console.error(e);
    }

    await dispatch('fetch', true);
  }
};
