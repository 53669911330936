import Vue from 'vue';

/*
    Met deze directive kan je op elke knop, link of wat dan ook aangeven of er een feature voor benodigd is.

    Je kunt het als volgt gebruiken:

    <button @click="executeFeature" v-feature.municipal-budgets="filter.name == 'moreFilters'">Municipal Budgets</button>
*/

Vue.directive('feature', {
  inserted (el, binding, vnode) {
    if (!vnode.context || !binding.arg) { return; }

    const { $auth, $user, $store } = vnode.context;

    const eventHandler = (event) => {
      if (binding.value === false) {
        return;
      }

      const feature = binding.arg.replace(/\w+/g, function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      }).replace(/-/g, '');

      if ($auth.loggedIn && $user.team !== false && $user.team.features.some(f => f.access === feature && f.isEnabled)) {
        return;
      }

      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();

      $store.commit('proModal/showModal', { feature, blockPage: !!binding.modifiers.block });
    };

    el.addEventListener('click', eventHandler, {
      capture: true
    });
  }
});
