import Filter from './Filter';

export default class SearchWordsFilter extends Filter {
  get name () {
    return 'searchWords';
  }

  get label () {
    return 'Zoekwoorden';
  }

  get UI () {
    return 'SearchWords';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  count (options) {
    if (options.length > 0) { return options.length; }
  }

  isActive (options) {
    return options.length > 0;
  }

  fromData (data) {
    const options = super.fromData(data);
    const words = super.fromData(options);

    return words.map(x => ({ word: x.word, exclude: x.exclude === 'true' }));
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = searchprofile.searchWords?.length > 0;
    filter.options = searchprofile.searchWords ?? [];
  }

  toSearchProfile (searchWords) {
    return {
      searchWords
    };
  }
}
