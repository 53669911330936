export const state = () => ({
  stack: []
});

export const mutations = {
  navigate (state, data) {
    state.stack.push(data);
  },

  back (state) {
    const { exitCallback } = state.stack.pop();
    exitCallback();
  }
};
