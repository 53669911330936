
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: undefined
    },
    positiveButton: {
      type: Object,
      default: undefined
    },
    negativeButton: {
      type: Object,
      default: undefined
    },

    compact: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      internalshow: this.show || false,
      internalcompact: this.compact || false,
      internaltitle: this.title,
      internaldestructive: !!this.destructive,
      body: '',
      internalpositiveButton: this.positiveButton,
      internalnegativeButton: this.negativeButton
    };
  },
  watch: {
    show () {
      this.internalshow = this.show;
    },

    async internalshow () {
      if (this.internalshow) {
        document.body.style.paddingRight =
            window.innerWidth - document.body.clientWidth + 'px';
        document.body.style.overflow = 'hidden';

        await this.$nextTick();
        this.$el.querySelector('button.positive')?.focus();
      } else { document.body.style.overflow = 'auto'; }
      document.body.style.paddingRight = 0;
    }
  },

  mounted () {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' && this.show) { this.close(); }
    });
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (this.show !== undefined) { return; }

      if (mutation.type === 'modal/showModal') {
        this.internalshow = true;
        this.internalcompact = state.modal.compact;
        this.internaldestructive = state.modal.destructive;
        this.internaltitle = state.modal.title;
        this.body = state.modal.body;
        this.internalpositiveButton = state.modal.positiveButton;
        this.internalnegativeButton = state.modal.negativeButton;
      } else if (mutation.type === 'modal/closeModal') {
        this.internalshow = false;
      }
    });
  },

  methods: {
    close () {
      this.$emit('close');
      if (this.show === undefined) { this.$modal.closeModal(); }
    }
  }
};
