export default class Text {
  static toCamelCase (text) {
    return text.replace(/-\w/g, Text.clearAndUpper);
  }

  static toPascalCase (text) {
    return text.replace(/(^\w|-\w)/g, Text.clearAndUpper);
  }

  static toKebabCase (text) {
    return text.replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase();
  }

  static clearAndUpper (text) {
    return text.replace(/-/, '').toUpperCase();
  }
}
