import Vue from 'vue';

/**
 * @param {string} type Store type, so for example `tender` the store should validate to this format `<type>SearchWords.js`
 * @param {Object} options Additional options for api calls
 * @param {string} options.overviewStore Which store contains the scanner filter?
 * @param {string} options.url The api call url
 * @param {string} options.queryID Which query param should be used to add the id, for example `tenderid` or `municipalbudgetid`
 */
export default function createSearchWordStore (type, options) {
  const overviewStore = options?.overviewStore ?? 'overview';
  const url = options?.url ?? 'tendersearchwordmatches';
  const queryID = options?.queryID ?? 'tenderid';

  return {
    state: () => ({
      results: {},
      queue: [],
      processing: false
    }),

    mutations: {
      requeue (state) {
        state.queue = Object.keys(state.results).reverse();
        state.results = {};

        this.dispatch(`${type}SearchWords/process`);
      },

      enqueue (state, id) {
        if (state.queue.includes(id) || id in state.results) { return; }

        state.queue.unshift(id);

        if (!state.processing) { this.dispatch(`${type}SearchWords/process`); }
      },

      dequeue (state) {
        const item = state.queue.pop();

        Vue.set(state, 'queue', state.queue);

        return item;
      },

      stop (state) {
        state.queue = [];

        this.commit(`${type}SearchWords/setProcessing`, false);
      },

      clear (state) {
        state.queue = [];
        state.results = {};

        this.commit(`${type}SearchWords/setProcessing`, false);
      },

      setResult (state, { id, result }) {
        Vue.set(state.results, id, result.matches);
      },

      setProcessing (state, processing) {
        state.processing = processing;
      }
    },

    getters: {
      searchwords (_0, _1, rootState) {
        return rootState[overviewStore].filters.find(x => x.name === 'scanner')?.options ?? [];
      }
    },

    actions: {
      async process ({ state, commit, dispatch, getters }) {
        const searchwords = getters.searchwords;
        if (searchwords.length === 0 || state.queue.length === 0) {
          commit('stop');
          commit('setProcessing', false);
          return;
        }

        const [id] = state.queue.slice(-1);
        commit('dequeue');

        if (!id) {
          commit('setProcessing', false);
          return;
        }

        commit('setProcessing', true);

        const params = new URLSearchParams({
          [queryID]: id
        });

        for (const word of getters.searchwords) { params.append('searchWords', word.word); }

        const result = await this.$axios.$get(
          `/${url}?${params.toString()}`
        );

        commit('setResult', { id, result });
        dispatch('process');
      }

    }
  };
}
