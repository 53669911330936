import { render, staticRenderFns } from "./settings.vue?vue&type=template&id=6d3fc622&scoped=true"
import script from "./settings.vue?vue&type=script&lang=js"
export * from "./settings.vue?vue&type=script&lang=js"
import style0 from "./settings.vue?vue&type=style&index=0&id=6d3fc622&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3fc622",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/app/components/modal.vue').default,Navbar: require('/app/components/Navbar.vue').default,AlturaButton: require('/app/node_modules/@altura/components/components/Button.vue').default,ProModal: require('/app/components/ProModal.vue').default,AlturaContainer: require('/app/node_modules/@altura/components/components/Container.vue').default,AlturaLogo: require('/app/node_modules/@altura/components/components/Logo.vue').default})
