export const state = () => ({
  platformSettings: null
});
export const mutations = {
  reset () {
    this.commit('overview/reset');
    this.commit('municipalBudgetsOverview/reset');
    this.commit('SellingOrganizationOverview/reset');
    this.commit('BuyingOrganizationOverview/reset');
    // this.commit('teams/reset');
  },

  setPlatformSettings (state, platformSettings) {
    try {
      state.platformSettings = platformSettings;
    } catch {}
  }
};

export const actions = {
  async nuxtClientInit ({ dispatch }) {
  },

  onLogin ({ dispatch }) {
    if (!window.loadPendo) {
      return;
    }
    window.loadPendo();
  },

  onTeamSwitch () {
    if (!window.pendo) {
      return;
    }

    window.pendo.updateOptions({
      visitor: {
        id: this.$auth.user.id, // Required if user is logged in, default creates anonymous ID
        email: this.$auth.user.email, // Recommended if using Pendo Feedback, or NPS Email
        full_name: this.$auth.user.fullName, // Recommended if using Pendo Feedback
        role: Object.values(this.$auth.user.roles[this.$user.team.id]).map(x => x.name).join(', ') // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: this.$user.team.id,
        teamName: this.$user.team.company,
        userCount: this.$user.teamMates.length,
        features: this.$user.team.features.map(x => x.access).join(', '),
        plaform: this.$user.team.platforms[0]
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  },

  async nuxtServerInit ({ dispatch }) {
  }
};
