
import modal from '@/components/modal.vue';
import HolidayMixin from '@/mixins/HolidayMixin.js';

export default {
  components: { modal },

  mixins: [HolidayMixin],

  data () {
    return {
      navOpen: this.$device.isMobile,
      navigation: [],
      title: 'Instellingen'
    };
  },

  watch: {
    $route () {
      setTimeout(() => {
        this.updateTitle();
      }, 1000);
      this.navOpen = false;
    },

    '$user.team' () {
      this.updateNavigation();
    }
  },

  mounted () {
    this.updateNavigation();
  },

  methods: {
    openMenu () {
      if (this.navOpen) {
        this.$router.push('/');
        return;
      }
      this.navOpen = true;
    },

    updateTitle () {
      if (process.browser) {
        this.title = document.title.replace('Altura | ', '');
      }
    },

    updateNavigation () {
      const items = [
        {
          url: '/settings/profile',
          name: 'Profiel instellingen'
        },
        {
          url: '/settings/organization',
          name: 'Organisatie instellingen'
        },
        ...(this.$user.team.settings.ssoEnabled
          ? []
          : [{
            url: '/settings/password',
            name: 'Wachtwoord wijzigen'
          },
          {
            url: '/settings/email',
            name: 'E-mailadres wijzigen'
          }]),
        {
          url: '/settings/notification',
          name: 'Notificatie-instellingen'
        }
      ];

      if (this.$user.hasFeature('BidNoBid')) {
        items.push({
          url: '/settings/bid-no-bid',
          name: 'Bid/no-bid instellingen'
        });
      }
      // todo: Reenable when the redesign is done.
      // if (this.$user.hasRole('Billing') || this.$user.hasRole('Administrator')) {
      //   items.push({
      //     url: '/settings/subscription',
      //     name: 'Abonnement'
      //   });
      // }

      this.navigation = items;
    }
  }
};
