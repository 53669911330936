import { DateTime } from 'luxon';
import Filter from './Filter';

export default class ProposalDeadlineFilter extends Filter {
  get name () {
    return 'proposal-deadline';
  }

  get label () {
    return 'Inschrijfdeadline datum';
  }

  get UI () {
    return 'proposal-deadline';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = {
      minDate: '',
      maxDate: '',
      dateComparisonType: '',
      amountOfDateUnits: 0,
      dateUnitType: '',
      includeUnknown: true
    };
  }

  isActive (options) {
    const isMinAndMaxNeeded =
      options.dateComparisonType === 'Between' && options.minDate !== '' && options.maxDate !== '';

    const isMinOrMaxNeeded =
      options.dateComparisonType !== '' && options.dateComparisonType !== 'Between' &&
      options.dateUnitType === '' && options.amountOfDateUnits === 0 &&
      (options.minDate !== '' || options.maxDate !== '');

    const isUnitTypeNeeded =
      options.dateComparisonType !== '' && options.dateComparisonType !== 'Between' &&
      options.dateUnitType !== '' && options.amountOfDateUnits > 0;

    return isMinOrMaxNeeded || isUnitTypeNeeded || isMinAndMaxNeeded || !options.includeUnknown;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.applicationDeadline) {
      filter.options = searchprofile.applicationDeadline;
      filter.active = this.isActive(filter.options);
    }
  }

  toSearchProfile (options) {
    const searchProfile = {};
    if (this.isActive(options)) {
      if (options.minDate !== '') { searchProfile.minDate = DateTime.fromISO(options.minDate).toUTC(); }
      if (options.maxDate !== '') { searchProfile.maxDate = DateTime.fromISO(options.maxDate).toUTC(); }
      if (options.dateComparisonType !== '') { searchProfile.dateComparisonType = options.dateComparisonType; }
      if (options.amountOfDateUnits > 0) { searchProfile.amountOfDateUnits = options.amountOfDateUnits; }
      if (options.dateUnitType !== '') { searchProfile.dateUnitType = options.dateUnitType; }
      searchProfile.includeUnknown = options.includeUnknown;
      return { applicationDeadline: searchProfile };
    } else {
      return searchProfile;
    }
  }

  fromData (options) {
    const data = super.fromData(options);
    return {
      minDate: data.minDate,
      maxDate: data.maxDate,
      dateComparisonType: data.dateComparisonType,
      amountOfDateUnits: Number(data.amountOfDateUnits),
      dateUnitType: data.dateUnitType,
      includeUnknown: data.includeUnknown === 'true'
    };
  }
}
