import Vue from 'vue';
export const state = () => ({
  email: '',
  legalName: '',
  address: '',
  postalCode: '',
  city: '',
  coCNumber: '',
  taxId: ''
});

export const mutations = {
  loadInfo (state) {
    if (!process.browser) { return; }

    const data = localStorage.getItem('billing-info');

    if (!data) { return; }

    for (const [key, value] of Object.entries(JSON.parse(data))) {
      if (key in state) { Vue.set(state, key, value); }
    }
  },
  setInfo (state, data) {
    for (const [key, value] of Object.entries(data)) {
      if (key in state) { Vue.set(state, key, value); }
    }

    if (process.browser) {
      localStorage.setItem('billing-info', JSON.stringify(state));
    }
  }
};
