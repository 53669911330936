import TextTransformer from '~/app/textTransformer';

export default class Filter {
  get name () {
    return 'filter';
  }

  get label () {
    return this.name;
  }

  get UI () {
    return 'none';
  }

  get pro () {
    return false;
  }

  get updateResults () {
    return true;
  }

  constructor () {
    this.options = {};
  }

  getOptions () {
    if (this.options === undefined) {
      console.warn(`${this.name} has undefined options. This may be an error.`);
    }

    const UI = typeof this.UI === 'string'
      ? TextTransformer.toKebabCase(this.UI)
      : {
        ...this.UI,
        name: TextTransformer.toKebabCase(this.UI.name)
      };
    try {
      return {
        name: this.name,
        label: this.label,
        active: false,
        UI,
        pro: this.pro,
        options: this.options !== undefined ? JSON.parse(JSON.stringify(this.options)) : undefined
      };
    } catch (e) {
      console.error(e);
    }
  }

  toSearchProfile () {
    throw new Error('Not implemented.');
  }

  updateFromSearchProfile (searchProfile) {
    throw new Error(this.name + ' is missing updateFromSearchProfile().');
  }

  count (options) {
    return undefined;
  }

  isActive (options) {
    return JSON.stringify(this.getOptions().options) !== JSON.stringify(options);
  }

  fromData (options) {
    if (Array.isArray(this.options) && Array.isArray(options)) { return [...options]; }

    if (typeof this.options === 'object' && typeof options === 'object') {
      return { ...this.options, ...options };
    }

    return options;
  }

  toJSON () {
    return this.getOptions();
  }

  onUpdate (store, options) {

  }

  onDisable (store, options) {

  }

  onEnable (store, options) {

  }
}
