import { render, staticRenderFns } from "./comingSoon.vue?vue&type=template&id=09683c51&scoped=true"
import script from "./comingSoon.vue?vue&type=script&lang=js"
export * from "./comingSoon.vue?vue&type=script&lang=js"
import style0 from "./comingSoon.vue?vue&type=style&index=0&id=09683c51&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09683c51",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/app/components/Navbar.vue').default,AlturaLogo: require('/app/node_modules/@altura/components/components/Logo.vue').default,Modal: require('/app/components/modal.vue').default})
