import Filter from './Filter';

export default class FinalExpirationDateFilter extends Filter {
  get name () {
    return 'final-expiration-date';
  }

  get label () {
    return 'Definitieve vervaldatum';
  }

  get UI () {
    return 'expiration-date';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.minimumFinalExpirationDate && searchprofile.maximumFinalExpirationDate) {
      filter.options = [searchprofile.minimumFinalExpirationDate, searchprofile.maximumFinalExpirationDate];
      filter.active = true;
    }
  }

  toSearchProfile (options) {
    if (options.length > 0) {
      return {
        minimumFinalExpirationDate: options[0],
        maximumFinalExpirationDate: options[1]
      };
    }
  }
}
