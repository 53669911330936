import Filter from './Filter';

export default class AwardCriteriaFilter extends Filter {
  get name () {
    return 'awardCriteria';
  }

  get label () {
    return 'Gunningscriteria';
  }

  get UI () {
    return 'AwardCriteria';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = {};
  }

  isActive (options) {
    return options.min >= 0 || options.max >= 0;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (
      searchprofile.awardCriteriaCollection &&
      searchprofile.awardCriteriaCollection.length > 0
    ) {
      const quality = searchprofile.awardCriteriaCollection[0];
      filter.options.min = quality.minPercentage;
      filter.options.max = quality.maxPercentage;

      filter.active = true;
    }
  }

  toSearchProfile (options) {
    if ((options.min >= 0 || options.max >= 0) && (options.min !== null && options.max !== null)) {
      return {
        awardCriteriaCollection: [
          {
            minPercentage: options.min ?? undefined,
            maxPercentage: options.max ?? undefined
          }
        ]
      };
    }

    return {};
  }
}
