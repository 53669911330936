import { deflate } from 'pako';
import qs from 'qs';
import Filters from '.';
import { apiFilters } from '~/mixins/FilterStore';
export default class SearchProfile {
  /**
   * @param {"searchprofile"|"filters"} source from which source would you like to make a searchProfile
   * @param {Object} filters
   */
  constructor (source, filters) {
    if (source === 'filters') {
      this.filters = filters;
      return;
    }

    this.filters = Filters.map((x) => {
      const options = x.getOptions();
      x.updateFromSearchProfile(filters, options);
      return options;
    });
  }

  toSearchProfile () {
    return apiFilters({ filters: this.filters });
  }

  async share () {
    const url = this.toUrl();

    const shareData = {
      url,
      text: 'Hey! Ik wil graag dit zoekprofiel met jou delen.',
      title: 'Hey! Ik wil graag dit zoekprofiel met jou delen.'
    };
    try {
      if (navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData);
        return 'share';
      }
    } catch {}

    try {
      await navigator.clipboard.writeText(url);
    } catch (e) {
      prompt('Kopieer de link van het zoekprofiel', url);
    }

    return 'copy';
  }

  toQueryString (search) {
    const querystring = qs.stringify(
      Object.fromEntries(
        [
          ['q', search || null],
          ...this.filters.filter(x => x.active).map(x => [x.name, x.options])
        ]
      ), { skipNulls: true, encodeValuesOnly: true, arrayFormat: 'indices', allowDots: true }
    );

    const buffer = Buffer.from(deflate(Buffer.from(querystring))).toString('base64');

    if (querystring.length > 1500 && querystring.length > buffer.length) {
      return `sp=${encodeURIComponent(buffer)}`;
    }

    return querystring;
  }

  toUrl (search) {
    const querystring = this.toQueryString(search);

    return `${location.protocol}//${location.host}${location.pathname}?${querystring}`;
  }
}
