import Filter from './Filter.js';

export default class CPVCodeFilter extends Filter {
  get name () {
    return 'CPV';
  }

  get UI () {
    return { name: 'CPV', resizable: true };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = {
      codes: [],
      mode: 'Tree'
    };
  }

  count (options) {
    return options.codes.length;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.cpvs && searchprofile.cpvs.length > 0) {
      filter.options = {
        codes: searchprofile.cpvs.map(x => x.code),
        mode: searchprofile.cpvMode
      };

      filter.active = true;
    }
  }

  toSearchProfile (options) {
    return {
      cpvs: options.codes.map(x => ({ code: x })),
      cpvMode: options.mode
    };
  }
}
