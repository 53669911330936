export const state = () => ({
  users: [],
  settings: {
    hasCompanyMandatedBidNoBidTemplates: false,
    companyMandatedBidNoBidTemplates: []
  },
  currentId: undefined
});

export const mutations = {
  /**
   * @param {Array} teams
   */
  setTeams (state, teams) {
    const sortedTeams = [...teams];
    sortedTeams.sort((a, b) => a.company.localeCompare(b.company));
    this.$auth.user.teams = sortedTeams;
  },

  pinTeam (state, team) {
    const pins = JSON.parse(localStorage.getItem('pinned_teams') ?? '[]');

    const index = this.$auth.user.teams.findIndex(x => x.id === team.id);
    this.$auth.user.teams[index].isPinned = true;

    if (pins.includes(team.id)) { return; }

    pins.push(team.id);

    localStorage.setItem('pinned_teams', JSON.stringify(pins));
  },

  unpinTeam (state, team) {
    const pins = JSON.parse(localStorage.getItem('pinned_teams') ?? '[]');

    const index = this.$auth.user.teams.findIndex(x => x.id === team.id);
    this.$auth.user.teams[index].isPinned = false;

    pins.splice(pins.indexOf(team.id), 1);

    localStorage.setItem('pinned_teams', JSON.stringify(pins));

    if (pins.length === 0) {
      localStorage.removeItem('pinned_teams');
    }
  },

  setCurrentTeam (state, id) {
    if (!this.$auth.user.teams.some(x => x.id === id.toLowerCase())) {
      console.warn(`Team ${id} doesn't exist in your teams.`);
      return;
    }

    state.currentId = id.toLowerCase();

    this.$axios.defaults.headers.teamId = id.toLowerCase();

    this.$cookies.set('team_id', id.toLowerCase(), {
      maxAge: 60 * 60 * 24 * 365,
      path: '/'
    });
  },

  reset (state) {
    state.currentId = undefined;

    if (process.browser) {
      this.$cookies.remove('team_id');
    }
  }
};

export const getters = {
  currentTeam (state) {
    return this.$auth.user.teams.find(x => x.id === state.currentId) ?? false;
  }
};

export const actions = {
  switchTeam ({ state, commit }, id) {
    if (state.currentId === id) {
      return;
    }

    commit('setCurrentTeam', id);

    this.dispatch('teams/loadCurrentTeam');
    this.dispatch('teams/loadTeamData');
  },

  loadCurrentTeam ({ commit }) {
    const teamId =
      this.$router.currentRoute.query.team_id ??
      this.$cookies.get('team_id');

    if (teamId) {
      commit('setCurrentTeam', teamId);
    }
  },
  async forceLoadTeams ({ commit }) {
    try {
      const teams = this.$auth.user.teams;

      if (process.browser) {
        const pinTeams = JSON.parse(
          localStorage.getItem('pinned_teams') ?? '[]'
        );
        for (const team of teams) {
          team.isPinned = pinTeams.includes(team.id);
        }
      }

      commit('setTeams', teams);

      if (teams.length === 1) {
        commit('setCurrentTeam', teams[0].id);
      }
      await Promise.all([
        this.dispatch('teams/loadCurrentTeam')
      ]);
    } catch {
      console.error('Failed to load teams.');
    }
  },

  async loadTeams ({ state }) {
    if (this.$auth.user.teams.length > 0 || !this.$auth.loggedIn) {
      return;
    }

    await this.dispatch('teams/forceLoadTeams');
  }
};
