export const AlturaAutoComplete = () => import('../../node_modules/@altura/components/components/AutoComplete.vue' /* webpackChunkName: "components/altura-auto-complete" */).then(c => wrapFunctional(c.default || c))
export const AlturaBanner = () => import('../../node_modules/@altura/components/components/Banner.vue' /* webpackChunkName: "components/altura-banner" */).then(c => wrapFunctional(c.default || c))
export const AlturaButton = () => import('../../node_modules/@altura/components/components/Button.vue' /* webpackChunkName: "components/altura-button" */).then(c => wrapFunctional(c.default || c))
export const AlturaButtonGroup = () => import('../../node_modules/@altura/components/components/ButtonGroup.vue' /* webpackChunkName: "components/altura-button-group" */).then(c => wrapFunctional(c.default || c))
export const AlturaCarrousel = () => import('../../node_modules/@altura/components/components/Carrousel.vue' /* webpackChunkName: "components/altura-carrousel" */).then(c => wrapFunctional(c.default || c))
export const AlturaCheckbox = () => import('../../node_modules/@altura/components/components/Checkbox.vue' /* webpackChunkName: "components/altura-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AlturaCheckboxGroup = () => import('../../node_modules/@altura/components/components/CheckboxGroup.vue' /* webpackChunkName: "components/altura-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const AlturaCode = () => import('../../node_modules/@altura/components/components/Code.vue' /* webpackChunkName: "components/altura-code" */).then(c => wrapFunctional(c.default || c))
export const AlturaCollapse = () => import('../../node_modules/@altura/components/components/Collapse.vue' /* webpackChunkName: "components/altura-collapse" */).then(c => wrapFunctional(c.default || c))
export const AlturaCommandBar = () => import('../../node_modules/@altura/components/components/CommandBar.vue' /* webpackChunkName: "components/altura-command-bar" */).then(c => wrapFunctional(c.default || c))
export const AlturaContainer = () => import('../../node_modules/@altura/components/components/Container.vue' /* webpackChunkName: "components/altura-container" */).then(c => wrapFunctional(c.default || c))
export const AlturaCreateList = () => import('../../node_modules/@altura/components/components/CreateList.vue' /* webpackChunkName: "components/altura-create-list" */).then(c => wrapFunctional(c.default || c))
export const AlturaDataTable = () => import('../../node_modules/@altura/components/components/DataTable.vue' /* webpackChunkName: "components/altura-data-table" */).then(c => wrapFunctional(c.default || c))
export const AlturaDragContainer = () => import('../../node_modules/@altura/components/components/DragContainer.vue' /* webpackChunkName: "components/altura-drag-container" */).then(c => wrapFunctional(c.default || c))
export const AlturaDropdown = () => import('../../node_modules/@altura/components/components/Dropdown.vue' /* webpackChunkName: "components/altura-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AlturaEditField = () => import('../../node_modules/@altura/components/components/EditField.vue' /* webpackChunkName: "components/altura-edit-field" */).then(c => wrapFunctional(c.default || c))
export const AlturaFlex = () => import('../../node_modules/@altura/components/components/Flex.vue' /* webpackChunkName: "components/altura-flex" */).then(c => wrapFunctional(c.default || c))
export const AlturaGoBack = () => import('../../node_modules/@altura/components/components/GoBack.vue' /* webpackChunkName: "components/altura-go-back" */).then(c => wrapFunctional(c.default || c))
export const AlturaIcon = () => import('../../node_modules/@altura/components/components/Icon.vue' /* webpackChunkName: "components/altura-icon" */).then(c => wrapFunctional(c.default || c))
export const AlturaInfo = () => import('../../node_modules/@altura/components/components/Info.vue' /* webpackChunkName: "components/altura-info" */).then(c => wrapFunctional(c.default || c))
export const AlturaLazyLoad = () => import('../../node_modules/@altura/components/components/LazyLoad.vue' /* webpackChunkName: "components/altura-lazy-load" */).then(c => wrapFunctional(c.default || c))
export const AlturaLogo = () => import('../../node_modules/@altura/components/components/Logo.vue' /* webpackChunkName: "components/altura-logo" */).then(c => wrapFunctional(c.default || c))
export const AlturaMultiSelect = () => import('../../node_modules/@altura/components/components/MultiSelect.vue' /* webpackChunkName: "components/altura-multi-select" */).then(c => wrapFunctional(c.default || c))
export const AlturaNumberInput = () => import('../../node_modules/@altura/components/components/NumberInput.vue' /* webpackChunkName: "components/altura-number-input" */).then(c => wrapFunctional(c.default || c))
export const AlturaPaginator = () => import('../../node_modules/@altura/components/components/Paginator.vue' /* webpackChunkName: "components/altura-paginator" */).then(c => wrapFunctional(c.default || c))
export const AlturaProfilePicture = () => import('../../node_modules/@altura/components/components/ProfilePicture.vue' /* webpackChunkName: "components/altura-profile-picture" */).then(c => wrapFunctional(c.default || c))
export const AlturaProgressbar = () => import('../../node_modules/@altura/components/components/Progressbar.vue' /* webpackChunkName: "components/altura-progressbar" */).then(c => wrapFunctional(c.default || c))
export const AlturaPrompt = () => import('../../node_modules/@altura/components/components/Prompt.vue' /* webpackChunkName: "components/altura-prompt" */).then(c => wrapFunctional(c.default || c))
export const AlturaRadio = () => import('../../node_modules/@altura/components/components/Radio.vue' /* webpackChunkName: "components/altura-radio" */).then(c => wrapFunctional(c.default || c))
export const AlturaScrollToTopButton = () => import('../../node_modules/@altura/components/components/ScrollToTopButton.vue' /* webpackChunkName: "components/altura-scroll-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const AlturaSegmentedButton = () => import('../../node_modules/@altura/components/components/SegmentedButton.vue' /* webpackChunkName: "components/altura-segmented-button" */).then(c => wrapFunctional(c.default || c))
export const AlturaSelect = () => import('../../node_modules/@altura/components/components/Select.vue' /* webpackChunkName: "components/altura-select" */).then(c => wrapFunctional(c.default || c))
export const AlturaSelectRect = () => import('../../node_modules/@altura/components/components/SelectRect.vue' /* webpackChunkName: "components/altura-select-rect" */).then(c => wrapFunctional(c.default || c))
export const AlturaSeparator = () => import('../../node_modules/@altura/components/components/Separator.vue' /* webpackChunkName: "components/altura-separator" */).then(c => wrapFunctional(c.default || c))
export const AlturaShareCard = () => import('../../node_modules/@altura/components/components/ShareCard.vue' /* webpackChunkName: "components/altura-share-card" */).then(c => wrapFunctional(c.default || c))
export const AlturaSidebarLayout = () => import('../../node_modules/@altura/components/components/SidebarLayout.vue' /* webpackChunkName: "components/altura-sidebar-layout" */).then(c => wrapFunctional(c.default || c))
export const AlturaSkeleton = () => import('../../node_modules/@altura/components/components/Skeleton.vue' /* webpackChunkName: "components/altura-skeleton" */).then(c => wrapFunctional(c.default || c))
export const AlturaSlider = () => import('../../node_modules/@altura/components/components/Slider.vue' /* webpackChunkName: "components/altura-slider" */).then(c => wrapFunctional(c.default || c))
export const AlturaSpinner = () => import('../../node_modules/@altura/components/components/Spinner.vue' /* webpackChunkName: "components/altura-spinner" */).then(c => wrapFunctional(c.default || c))
export const AlturaSwitch = () => import('../../node_modules/@altura/components/components/Switch.vue' /* webpackChunkName: "components/altura-switch" */).then(c => wrapFunctional(c.default || c))
export const AlturaTag = () => import('../../node_modules/@altura/components/components/Tag.vue' /* webpackChunkName: "components/altura-tag" */).then(c => wrapFunctional(c.default || c))
export const AlturaText = () => import('../../node_modules/@altura/components/components/Text.vue' /* webpackChunkName: "components/altura-text" */).then(c => wrapFunctional(c.default || c))
export const AlturaTextArea = () => import('../../node_modules/@altura/components/components/TextArea.vue' /* webpackChunkName: "components/altura-text-area" */).then(c => wrapFunctional(c.default || c))
export const AlturaTextbox = () => import('../../node_modules/@altura/components/components/Textbox.vue' /* webpackChunkName: "components/altura-textbox" */).then(c => wrapFunctional(c.default || c))
export const AlturaToast = () => import('../../node_modules/@altura/components/components/Toast.vue' /* webpackChunkName: "components/altura-toast" */).then(c => wrapFunctional(c.default || c))
export const AlturaToggleButton = () => import('../../node_modules/@altura/components/components/ToggleButton.vue' /* webpackChunkName: "components/altura-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const AlturaTooltip = () => import('../../node_modules/@altura/components/components/Tooltip.vue' /* webpackChunkName: "components/altura-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AlturaUpload = () => import('../../node_modules/@altura/components/components/Upload.vue' /* webpackChunkName: "components/altura-upload" */).then(c => wrapFunctional(c.default || c))
export const AlturaUserCollection = () => import('../../node_modules/@altura/components/components/UserCollection.vue' /* webpackChunkName: "components/altura-user-collection" */).then(c => wrapFunctional(c.default || c))
export const AlturaWindow = () => import('../../node_modules/@altura/components/components/Window.vue' /* webpackChunkName: "components/altura-window" */).then(c => wrapFunctional(c.default || c))
export const AlturaWizardHeader = () => import('../../node_modules/@altura/components/components/WizardHeader.vue' /* webpackChunkName: "components/altura-wizard-header" */).then(c => wrapFunctional(c.default || c))
export const AlturaDataGrid = () => import('../../node_modules/@altura/components/components/DataGrid/DataGrid.vue' /* webpackChunkName: "components/altura-data-grid" */).then(c => wrapFunctional(c.default || c))
export const AlturaDataHeader = () => import('../../node_modules/@altura/components/components/DataGrid/DataHeader.vue' /* webpackChunkName: "components/altura-data-header" */).then(c => wrapFunctional(c.default || c))
export const AlturaDataRow = () => import('../../node_modules/@altura/components/components/DataGrid/DataRow.vue' /* webpackChunkName: "components/altura-data-row" */).then(c => wrapFunctional(c.default || c))
export const AlturaDraggable = () => import('../../node_modules/@altura/components/components/Draggables/Draggable.vue' /* webpackChunkName: "components/altura-draggable" */).then(c => wrapFunctional(c.default || c))
export const AlturaDropzone = () => import('../../node_modules/@altura/components/components/Draggables/Dropzone.vue' /* webpackChunkName: "components/altura-dropzone" */).then(c => wrapFunctional(c.default || c))
export const AlturaAccordion = () => import('../../node_modules/@altura/components/components/accordion/Accordion.vue' /* webpackChunkName: "components/altura-accordion" */).then(c => wrapFunctional(c.default || c))
export const AlturaAccordionItem = () => import('../../node_modules/@altura/components/components/accordion/AccordionItem.vue' /* webpackChunkName: "components/altura-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AlturaCalendar = () => import('../../node_modules/@altura/components/components/calendar/Calendar.vue' /* webpackChunkName: "components/altura-calendar" */).then(c => wrapFunctional(c.default || c))
export const AlturaDateRange = () => import('../../node_modules/@altura/components/components/calendar/DateRange.vue' /* webpackChunkName: "components/altura-date-range" */).then(c => wrapFunctional(c.default || c))
export const AlturaBarChart = () => import('../../node_modules/@altura/components/components/charts/BarChart.vue' /* webpackChunkName: "components/altura-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const AlturaBarImpressionChart = () => import('../../node_modules/@altura/components/components/charts/BarImpressionChart.vue' /* webpackChunkName: "components/altura-bar-impression-chart" */).then(c => wrapFunctional(c.default || c))
export const AlturaPieChart = () => import('../../node_modules/@altura/components/components/charts/PieChart.vue' /* webpackChunkName: "components/altura-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const AlturaStackedMeter = () => import('../../node_modules/@altura/components/components/charts/StackedMeter.vue' /* webpackChunkName: "components/altura-stacked-meter" */).then(c => wrapFunctional(c.default || c))
export const AlturaSwatch = () => import('../../node_modules/@altura/components/components/docs/Swatch.vue' /* webpackChunkName: "components/altura-swatch" */).then(c => wrapFunctional(c.default || c))
export const AlturaTransition = () => import('../../node_modules/@altura/components/components/docs/Transition.vue' /* webpackChunkName: "components/altura-transition" */).then(c => wrapFunctional(c.default || c))
export const AlturaList = () => import('../../node_modules/@altura/components/components/list/List.vue' /* webpackChunkName: "components/altura-list" */).then(c => wrapFunctional(c.default || c))
export const AlturaListHeader = () => import('../../node_modules/@altura/components/components/list/ListHeader.vue' /* webpackChunkName: "components/altura-list-header" */).then(c => wrapFunctional(c.default || c))
export const AlturaListItem = () => import('../../node_modules/@altura/components/components/list/ListItem.vue' /* webpackChunkName: "components/altura-list-item" */).then(c => wrapFunctional(c.default || c))
export const AlturaVirtualList = () => import('../../node_modules/@altura/components/components/list/VirtualList.vue' /* webpackChunkName: "components/altura-virtual-list" */).then(c => wrapFunctional(c.default || c))
export const AlturaContextMenu = () => import('../../node_modules/@altura/components/components/menu/ContextMenu.vue' /* webpackChunkName: "components/altura-context-menu" */).then(c => wrapFunctional(c.default || c))
export const AlturaMenuItem = () => import('../../node_modules/@altura/components/components/menu/MenuItem.vue' /* webpackChunkName: "components/altura-menu-item" */).then(c => wrapFunctional(c.default || c))
export const AlturaOverflowMenu = () => import('../../node_modules/@altura/components/components/menu/OverflowMenu.vue' /* webpackChunkName: "components/altura-overflow-menu" */).then(c => wrapFunctional(c.default || c))
export const AlturaSettingRow = () => import('../../node_modules/@altura/components/components/settings/SettingRow.vue' /* webpackChunkName: "components/altura-setting-row" */).then(c => wrapFunctional(c.default || c))
export const AlturaTab = () => import('../../node_modules/@altura/components/components/tabs/Tab.vue' /* webpackChunkName: "components/altura-tab" */).then(c => wrapFunctional(c.default || c))
export const AlturaTabContainer = () => import('../../node_modules/@altura/components/components/tabs/TabContainer.vue' /* webpackChunkName: "components/altura-tab-container" */).then(c => wrapFunctional(c.default || c))
export const AlturaSkeletonTreeItem = () => import('../../node_modules/@altura/components/components/tree/SkeletonTreeItem.vue' /* webpackChunkName: "components/altura-skeleton-tree-item" */).then(c => wrapFunctional(c.default || c))
export const AlturaTree = () => import('../../node_modules/@altura/components/components/tree/Tree.vue' /* webpackChunkName: "components/altura-tree" */).then(c => wrapFunctional(c.default || c))
export const AlturaTreeItem = () => import('../../node_modules/@altura/components/components/tree/TreeItem.vue' /* webpackChunkName: "components/altura-tree-item" */).then(c => wrapFunctional(c.default || c))
export const AprilFoolsButton = () => import('../../components/AprilFoolsButton.vue' /* webpackChunkName: "components/april-fools-button" */).then(c => wrapFunctional(c.default || c))
export const AprilFoolsModal = () => import('../../components/AprilFoolsModal.vue' /* webpackChunkName: "components/april-fools-modal" */).then(c => wrapFunctional(c.default || c))
export const BarGraph = () => import('../../components/BarGraph.vue' /* webpackChunkName: "components/bar-graph" */).then(c => wrapFunctional(c.default || c))
export const Carrousel = () => import('../../components/Carrousel.vue' /* webpackChunkName: "components/carrousel" */).then(c => wrapFunctional(c.default || c))
export const EditField = () => import('../../components/EditField.vue' /* webpackChunkName: "components/edit-field" */).then(c => wrapFunctional(c.default || c))
export const FileIcon = () => import('../../components/FileIcon.vue' /* webpackChunkName: "components/file-icon" */).then(c => wrapFunctional(c.default || c))
export const FollowButton = () => import('../../components/FollowButton.vue' /* webpackChunkName: "components/follow-button" */).then(c => wrapFunctional(c.default || c))
export const MobileHistory = () => import('../../components/MobileHistory.vue' /* webpackChunkName: "components/mobile-history" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const PanelLayout = () => import('../../components/PanelLayout.vue' /* webpackChunkName: "components/panel-layout" */).then(c => wrapFunctional(c.default || c))
export const PlatformStatusBanner = () => import('../../components/PlatformStatusBanner.vue' /* webpackChunkName: "components/platform-status-banner" */).then(c => wrapFunctional(c.default || c))
export const PrintCoverPage = () => import('../../components/PrintCoverPage.vue' /* webpackChunkName: "components/print-cover-page" */).then(c => wrapFunctional(c.default || c))
export const ProModal = () => import('../../components/ProModal.vue' /* webpackChunkName: "components/pro-modal" */).then(c => wrapFunctional(c.default || c))
export const ProfileMenu = () => import('../../components/ProfileMenu.vue' /* webpackChunkName: "components/profile-menu" */).then(c => wrapFunctional(c.default || c))
export const Prompt = () => import('../../components/Prompt.vue' /* webpackChunkName: "components/prompt" */).then(c => wrapFunctional(c.default || c))
export const ProposalCard = () => import('../../components/ProposalCard.vue' /* webpackChunkName: "components/proposal-card" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../components/RichText.js' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const RichTextEditor = () => import('../../components/RichTextEditor.vue' /* webpackChunkName: "components/rich-text-editor" */).then(c => wrapFunctional(c.default || c))
export const StackedBar = () => import('../../components/StackedBar.vue' /* webpackChunkName: "components/stacked-bar" */).then(c => wrapFunctional(c.default || c))
export const TeamMateSelector = () => import('../../components/TeamMateSelector.vue' /* webpackChunkName: "components/team-mate-selector" */).then(c => wrapFunctional(c.default || c))
export const TierCard = () => import('../../components/TierCard.vue' /* webpackChunkName: "components/tier-card" */).then(c => wrapFunctional(c.default || c))
export const TrialBanner = () => import('../../components/TrialBanner.vue' /* webpackChunkName: "components/trial-banner" */).then(c => wrapFunctional(c.default || c))
export const WizardHeader = () => import('../../components/WizardHeader.vue' /* webpackChunkName: "components/wizard-header" */).then(c => wrapFunctional(c.default || c))
export const Clamp = () => import('../../components/clamp.vue' /* webpackChunkName: "components/clamp" */).then(c => wrapFunctional(c.default || c))
export const CocCard = () => import('../../components/cocCard.vue' /* webpackChunkName: "components/coc-card" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const BnbAnswerCard = () => import('../../components/bnb/AnswerCard.vue' /* webpackChunkName: "components/bnb-answer-card" */).then(c => wrapFunctional(c.default || c))
export const BnbCard = () => import('../../components/bnb/Card.vue' /* webpackChunkName: "components/bnb-card" */).then(c => wrapFunctional(c.default || c))
export const BnbCategoryCard = () => import('../../components/bnb/CategoryCard.vue' /* webpackChunkName: "components/bnb-category-card" */).then(c => wrapFunctional(c.default || c))
export const BnbEditQuestionCard = () => import('../../components/bnb/EditQuestionCard.vue' /* webpackChunkName: "components/bnb-edit-question-card" */).then(c => wrapFunctional(c.default || c))
export const BnbOptions = () => import('../../components/bnb/Options.vue' /* webpackChunkName: "components/bnb-options" */).then(c => wrapFunctional(c.default || c))
export const BnbQuestionCard = () => import('../../components/bnb/QuestionCard.vue' /* webpackChunkName: "components/bnb-question-card" */).then(c => wrapFunctional(c.default || c))
export const BnbStackedMeter = () => import('../../components/bnb/StackedMeter.vue' /* webpackChunkName: "components/bnb-stacked-meter" */).then(c => wrapFunctional(c.default || c))
export const BnbTemplateCard = () => import('../../components/bnb/TemplateCard.vue' /* webpackChunkName: "components/bnb-template-card" */).then(c => wrapFunctional(c.default || c))
export const ChartsPieChart = () => import('../../components/charts/PieChart.vue' /* webpackChunkName: "components/charts-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const CollectionsSearch = () => import('../../components/collections/Search.vue' /* webpackChunkName: "components/collections-search" */).then(c => wrapFunctional(c.default || c))
export const DetailAddToMyTendersButton = () => import('../../components/detail/AddToMyTendersButton.vue' /* webpackChunkName: "components/detail-add-to-my-tenders-button" */).then(c => wrapFunctional(c.default || c))
export const DetailAiSummary = () => import('../../components/detail/AiSummary.vue' /* webpackChunkName: "components/detail-ai-summary" */).then(c => wrapFunctional(c.default || c))
export const DetailBidCard = () => import('../../components/detail/BidCard.vue' /* webpackChunkName: "components/detail-bid-card" */).then(c => wrapFunctional(c.default || c))
export const DetailCalendarExport = () => import('../../components/detail/CalendarExport.vue' /* webpackChunkName: "components/detail-calendar-export" */).then(c => wrapFunctional(c.default || c))
export const DetailCompetition = () => import('../../components/detail/Competition.vue' /* webpackChunkName: "components/detail-competition" */).then(c => wrapFunctional(c.default || c))
export const DetailConsultantWindow = () => import('../../components/detail/ConsultantWindow.vue' /* webpackChunkName: "components/detail-consultant-window" */).then(c => wrapFunctional(c.default || c))
export const DetailDocumentChat = () => import('../../components/detail/DocumentChat.vue' /* webpackChunkName: "components/detail-document-chat" */).then(c => wrapFunctional(c.default || c))
export const DetailDocumentTabs = () => import('../../components/detail/DocumentTabs.vue' /* webpackChunkName: "components/detail-document-tabs" */).then(c => wrapFunctional(c.default || c))
export const DetailMainInfo = () => import('../../components/detail/MainInfo.vue' /* webpackChunkName: "components/detail-main-info" */).then(c => wrapFunctional(c.default || c))
export const DetailOrganizationCard = () => import('../../components/detail/OrganizationCard.vue' /* webpackChunkName: "components/detail-organization-card" */).then(c => wrapFunctional(c.default || c))
export const DetailPdfViewer = () => import('../../components/detail/PdfViewer.vue' /* webpackChunkName: "components/detail-pdf-viewer" */).then(c => wrapFunctional(c.default || c))
export const DetailPlanning = () => import('../../components/detail/Planning.vue' /* webpackChunkName: "components/detail-planning" */).then(c => wrapFunctional(c.default || c))
export const DetailPreviewPage = () => import('../../components/detail/PreviewPage.vue' /* webpackChunkName: "components/detail-preview-page" */).then(c => wrapFunctional(c.default || c))
export const DetailPublicationField = () => import('../../components/detail/PublicationField.vue' /* webpackChunkName: "components/detail-publication-field" */).then(c => wrapFunctional(c.default || c))
export const DetailRelatedTenders = () => import('../../components/detail/RelatedTenders.vue' /* webpackChunkName: "components/detail-related-tenders" */).then(c => wrapFunctional(c.default || c))
export const DetailSummaryEnhancer = () => import('../../components/detail/SummaryEnhancer.vue' /* webpackChunkName: "components/detail-summary-enhancer" */).then(c => wrapFunctional(c.default || c))
export const DetailTranslationButton = () => import('../../components/detail/TranslationButton.vue' /* webpackChunkName: "components/detail-translation-button" */).then(c => wrapFunctional(c.default || c))
export const DetailValueCollapse = () => import('../../components/detail/ValueCollapse.vue' /* webpackChunkName: "components/detail-value-collapse" */).then(c => wrapFunctional(c.default || c))
export const DetailCondition = () => import('../../components/detail/condition.vue' /* webpackChunkName: "components/detail-condition" */).then(c => wrapFunctional(c.default || c))
export const DetailCriterium = () => import('../../components/detail/criterium.vue' /* webpackChunkName: "components/detail-criterium" */).then(c => wrapFunctional(c.default || c))
export const DetailDocument = () => import('../../components/detail/document.vue' /* webpackChunkName: "components/detail-document" */).then(c => wrapFunctional(c.default || c))
export const DetailPage = () => import('../../components/detail/page.vue' /* webpackChunkName: "components/detail-page" */).then(c => wrapFunctional(c.default || c))
export const DndDraggable = () => import('../../components/dnd/Draggable.vue' /* webpackChunkName: "components/dnd-draggable" */).then(c => wrapFunctional(c.default || c))
export const DndDropzone = () => import('../../components/dnd/Dropzone.vue' /* webpackChunkName: "components/dnd-dropzone" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultAddFileWindow = () => import('../../components/document-vault/AddFileWindow.vue' /* webpackChunkName: "components/document-vault-add-file-window" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultFilePicker = () => import('../../components/document-vault/FilePicker.vue' /* webpackChunkName: "components/document-vault-file-picker" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultFilePreview = () => import('../../components/document-vault/FilePreview.vue' /* webpackChunkName: "components/document-vault-file-preview" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultFolderButton = () => import('../../components/document-vault/FolderButton.vue' /* webpackChunkName: "components/document-vault-folder-button" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultFolderHeader = () => import('../../components/document-vault/FolderHeader.vue' /* webpackChunkName: "components/document-vault-folder-header" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultMoveWindow = () => import('../../components/document-vault/MoveWindow.vue' /* webpackChunkName: "components/document-vault-move-window" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultRecentFileCard = () => import('../../components/document-vault/RecentFileCard.vue' /* webpackChunkName: "components/document-vault-recent-file-card" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSearch = () => import('../../components/document-vault/Search.vue' /* webpackChunkName: "components/document-vault-search" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSelectFilesWindow = () => import('../../components/document-vault/SelectFilesWindow.vue' /* webpackChunkName: "components/document-vault-select-files-window" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultWindowFileDrop = () => import('../../components/document-vault/WindowFileDrop.vue' /* webpackChunkName: "components/document-vault-window-file-drop" */).then(c => wrapFunctional(c.default || c))
export const DragContainer = () => import('../../components/drag/Container.vue' /* webpackChunkName: "components/drag-container" */).then(c => wrapFunctional(c.default || c))
export const DragItem = () => import('../../components/drag/Item.vue' /* webpackChunkName: "components/drag-item" */).then(c => wrapFunctional(c.default || c))
export const EvaluationAddCompetitorPanel = () => import('../../components/evaluation/AddCompetitorPanel.vue' /* webpackChunkName: "components/evaluation-add-competitor-panel" */).then(c => wrapFunctional(c.default || c))
export const EvaluationAddCriteriumPanel = () => import('../../components/evaluation/AddCriteriumPanel.vue' /* webpackChunkName: "components/evaluation-add-criterium-panel" */).then(c => wrapFunctional(c.default || c))
export const EvaluationAddScorePanel = () => import('../../components/evaluation/AddScorePanel.vue' /* webpackChunkName: "components/evaluation-add-score-panel" */).then(c => wrapFunctional(c.default || c))
export const EvaluationBidPositionSlot = () => import('../../components/evaluation/BidPositionSlot.vue' /* webpackChunkName: "components/evaluation-bid-position-slot" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCard = () => import('../../components/evaluation/Card.vue' /* webpackChunkName: "components/evaluation-card" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCompetitorCard = () => import('../../components/evaluation/CompetitorCard.vue' /* webpackChunkName: "components/evaluation-competitor-card" */).then(c => wrapFunctional(c.default || c))
export const EvaluationOrganizationCard = () => import('../../components/evaluation/OrganizationCard.vue' /* webpackChunkName: "components/evaluation-organization-card" */).then(c => wrapFunctional(c.default || c))
export const EvaluationPanel = () => import('../../components/evaluation/Panel.vue' /* webpackChunkName: "components/evaluation-panel" */).then(c => wrapFunctional(c.default || c))
export const EvaluationSlot = () => import('../../components/evaluation/Slot.vue' /* webpackChunkName: "components/evaluation-slot" */).then(c => wrapFunctional(c.default || c))
export const LoginMicrosoftButton = () => import('../../components/login/MicrosoftButton.vue' /* webpackChunkName: "components/login-microsoft-button" */).then(c => wrapFunctional(c.default || c))
export const LoginTeamSelector = () => import('../../components/login/TeamSelector.vue' /* webpackChunkName: "components/login-team-selector" */).then(c => wrapFunctional(c.default || c))
export const LoginTeamCard = () => import('../../components/login/teamCard.vue' /* webpackChunkName: "components/login-team-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCard = () => import('../../components/market-insights/Card.vue' /* webpackChunkName: "components/market-insights-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsEntityCard = () => import('../../components/market-insights/EntityCard.vue' /* webpackChunkName: "components/market-insights-entity-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsEntityLabel = () => import('../../components/market-insights/EntityLabel.vue' /* webpackChunkName: "components/market-insights-entity-label" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsEntitySearchWindow = () => import('../../components/market-insights/EntitySearchWindow.vue' /* webpackChunkName: "components/market-insights-entity-search-window" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsHeader = () => import('../../components/market-insights/Header.vue' /* webpackChunkName: "components/market-insights-header" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsOrganizationCell = () => import('../../components/market-insights/OrganizationCell.vue' /* webpackChunkName: "components/market-insights-organization-cell" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsProgressBar = () => import('../../components/market-insights/ProgressBar.vue' /* webpackChunkName: "components/market-insights-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsSimpleFilter = () => import('../../components/market-insights/SimpleFilter.vue' /* webpackChunkName: "components/market-insights-simple-filter" */).then(c => wrapFunctional(c.default || c))
export const MessisAdvisorsInSegment = () => import('../../components/messis/AdvisorsInSegment.vue' /* webpackChunkName: "components/messis-advisors-in-segment" */).then(c => wrapFunctional(c.default || c))
export const MessisCard = () => import('../../components/messis/Card.vue' /* webpackChunkName: "components/messis-card" */).then(c => wrapFunctional(c.default || c))
export const MessisCurrentSuppliers = () => import('../../components/messis/CurrentSuppliers.vue' /* webpackChunkName: "components/messis-current-suppliers" */).then(c => wrapFunctional(c.default || c))
export const MessisDetailWindow = () => import('../../components/messis/DetailWindow.vue' /* webpackChunkName: "components/messis-detail-window" */).then(c => wrapFunctional(c.default || c))
export const MessisDurationContract = () => import('../../components/messis/DurationContract.vue' /* webpackChunkName: "components/messis-duration-contract" */).then(c => wrapFunctional(c.default || c))
export const MessisExpiringContracts = () => import('../../components/messis/ExpiringContracts.vue' /* webpackChunkName: "components/messis-expiring-contracts" */).then(c => wrapFunctional(c.default || c))
export const MessisLeadTimeTenderProcedure = () => import('../../components/messis/LeadTimeTenderProcedure.vue' /* webpackChunkName: "components/messis-lead-time-tender-procedure" */).then(c => wrapFunctional(c.default || c))
export const MessisLotDistribution = () => import('../../components/messis/LotDistribution.vue' /* webpackChunkName: "components/messis-lot-distribution" */).then(c => wrapFunctional(c.default || c))
export const MessisPrematurelyClosedChance = () => import('../../components/messis/PrematurelyClosedChance.vue' /* webpackChunkName: "components/messis-prematurely-closed-chance" */).then(c => wrapFunctional(c.default || c))
export const MessisPriceQualityPrevalence = () => import('../../components/messis/PriceQualityPrevalence.vue' /* webpackChunkName: "components/messis-price-quality-prevalence" */).then(c => wrapFunctional(c.default || c))
export const MessisPublicationPlatformDistribution = () => import('../../components/messis/PublicationPlatformDistribution.vue' /* webpackChunkName: "components/messis-publication-platform-distribution" */).then(c => wrapFunctional(c.default || c))
export const MessisSellerCountryDistribution = () => import('../../components/messis/SellerCountryDistribution.vue' /* webpackChunkName: "components/messis-seller-country-distribution" */).then(c => wrapFunctional(c.default || c))
export const MessisSellerDistribution = () => import('../../components/messis/SellerDistribution.vue' /* webpackChunkName: "components/messis-seller-distribution" */).then(c => wrapFunctional(c.default || c))
export const MessisSellerFluctuation = () => import('../../components/messis/SellerFluctuation.vue' /* webpackChunkName: "components/messis-seller-fluctuation" */).then(c => wrapFunctional(c.default || c))
export const MessisSellersInSegment = () => import('../../components/messis/SellersInSegment.vue' /* webpackChunkName: "components/messis-sellers-in-segment" */).then(c => wrapFunctional(c.default || c))
export const MessisSingleOrMultiSourcing = () => import('../../components/messis/SingleOrMultiSourcing.vue' /* webpackChunkName: "components/messis-single-or-multi-sourcing" */).then(c => wrapFunctional(c.default || c))
export const MessisStatistic = () => import('../../components/messis/Statistic.vue' /* webpackChunkName: "components/messis-statistic" */).then(c => wrapFunctional(c.default || c))
export const MessisTenderValueDistribution = () => import('../../components/messis/TenderValueDistribution.vue' /* webpackChunkName: "components/messis-tender-value-distribution" */).then(c => wrapFunctional(c.default || c))
export const MessisUsedProcedures = () => import('../../components/messis/UsedProcedures.vue' /* webpackChunkName: "components/messis-used-procedures" */).then(c => wrapFunctional(c.default || c))
export const MessisYearlyTenderDistribution = () => import('../../components/messis/YearlyTenderDistribution.vue' /* webpackChunkName: "components/messis-yearly-tender-distribution" */).then(c => wrapFunctional(c.default || c))
export const MunicipalCard = () => import('../../components/municipal-budgets/MunicipalCard.vue' /* webpackChunkName: "components/municipal-card" */).then(c => wrapFunctional(c.default || c))
export const MunicipalDocument = () => import('../../components/municipal-budgets/municipalDocument.vue' /* webpackChunkName: "components/municipal-document" */).then(c => wrapFunctional(c.default || c))
export const MyTendersAvatarCollection = () => import('../../components/my-tenders/AvatarCollection.vue' /* webpackChunkName: "components/my-tenders-avatar-collection" */).then(c => wrapFunctional(c.default || c))
export const MyTendersBidNoBid = () => import('../../components/my-tenders/BidNoBid.vue' /* webpackChunkName: "components/my-tenders-bid-no-bid" */).then(c => wrapFunctional(c.default || c))
export const MyTendersCard = () => import('../../components/my-tenders/Card.vue' /* webpackChunkName: "components/my-tenders-card" */).then(c => wrapFunctional(c.default || c))
export const MyTendersComments = () => import('../../components/my-tenders/Comments.vue' /* webpackChunkName: "components/my-tenders-comments" */).then(c => wrapFunctional(c.default || c))
export const MyTendersCustomField = () => import('../../components/my-tenders/CustomField.vue' /* webpackChunkName: "components/my-tenders-custom-field" */).then(c => wrapFunctional(c.default || c))
export const MyTendersDetails = () => import('../../components/my-tenders/Details.vue' /* webpackChunkName: "components/my-tenders-details" */).then(c => wrapFunctional(c.default || c))
export const MyTendersDocuments = () => import('../../components/my-tenders/Documents.vue' /* webpackChunkName: "components/my-tenders-documents" */).then(c => wrapFunctional(c.default || c))
export const MyTendersFieldSelector = () => import('../../components/my-tenders/FieldSelector.vue' /* webpackChunkName: "components/my-tenders-field-selector" */).then(c => wrapFunctional(c.default || c))
export const MyTendersNewProjectCard = () => import('../../components/my-tenders/NewProjectCard.vue' /* webpackChunkName: "components/my-tenders-new-project-card" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPlanning = () => import('../../components/my-tenders/Planning.vue' /* webpackChunkName: "components/my-tenders-planning" */).then(c => wrapFunctional(c.default || c))
export const MyTendersProjectCard = () => import('../../components/my-tenders/ProjectCard.vue' /* webpackChunkName: "components/my-tenders-project-card" */).then(c => wrapFunctional(c.default || c))
export const MyTendersProjectMemberEditor = () => import('../../components/my-tenders/ProjectMemberEditor.vue' /* webpackChunkName: "components/my-tenders-project-member-editor" */).then(c => wrapFunctional(c.default || c))
export const MyTendersProjectMembers = () => import('../../components/my-tenders/ProjectMembers.vue' /* webpackChunkName: "components/my-tenders-project-members" */).then(c => wrapFunctional(c.default || c))
export const MyTendersStartBidNoBidWindow = () => import('../../components/my-tenders/StartBidNoBidWindow.vue' /* webpackChunkName: "components/my-tenders-start-bid-no-bid-window" */).then(c => wrapFunctional(c.default || c))
export const MyTendersSwimlane = () => import('../../components/my-tenders/Swimlane.vue' /* webpackChunkName: "components/my-tenders-swimlane" */).then(c => wrapFunctional(c.default || c))
export const MyTendersSwimlaneContainer = () => import('../../components/my-tenders/SwimlaneContainer.vue' /* webpackChunkName: "components/my-tenders-swimlane-container" */).then(c => wrapFunctional(c.default || c))
export const MyTendersTasks = () => import('../../components/my-tenders/Tasks.vue' /* webpackChunkName: "components/my-tenders-tasks" */).then(c => wrapFunctional(c.default || c))
export const OnboardingDemoBanner = () => import('../../components/onboarding/DemoBanner.vue' /* webpackChunkName: "components/onboarding-demo-banner" */).then(c => wrapFunctional(c.default || c))
export const OnboardingWindow = () => import('../../components/onboarding/window.vue' /* webpackChunkName: "components/onboarding-window" */).then(c => wrapFunctional(c.default || c))
export const OrganizationCard = () => import('../../components/organization/Card.vue' /* webpackChunkName: "components/organization-card" */).then(c => wrapFunctional(c.default || c))
export const OrganizationProOverlay = () => import('../../components/organization/ProOverlay.vue' /* webpackChunkName: "components/organization-pro-overlay" */).then(c => wrapFunctional(c.default || c))
export const OrganizationCocCard = () => import('../../components/organization/cocCard.vue' /* webpackChunkName: "components/organization-coc-card" */).then(c => wrapFunctional(c.default || c))
export const OverviewFilter = () => import('../../components/overview/Filter.vue' /* webpackChunkName: "components/overview-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFilterButton = () => import('../../components/overview/FilterButton.vue' /* webpackChunkName: "components/overview-filter-button" */).then(c => wrapFunctional(c.default || c))
export const OverviewFilterContainer = () => import('../../components/overview/FilterContainer.vue' /* webpackChunkName: "components/overview-filter-container" */).then(c => wrapFunctional(c.default || c))
export const OverviewFilterContainerItem = () => import('../../components/overview/FilterContainerItem.vue' /* webpackChunkName: "components/overview-filter-container-item" */).then(c => wrapFunctional(c.default || c))
export const OverviewFilterModal = () => import('../../components/overview/FilterModal.vue' /* webpackChunkName: "components/overview-filter-modal" */).then(c => wrapFunctional(c.default || c))
export const OverviewMoreFiltersModal = () => import('../../components/overview/MoreFiltersModal.vue' /* webpackChunkName: "components/overview-more-filters-modal" */).then(c => wrapFunctional(c.default || c))
export const OverviewSaveSearchProfileButton = () => import('../../components/overview/SaveSearchProfileButton.vue' /* webpackChunkName: "components/overview-save-search-profile-button" */).then(c => wrapFunctional(c.default || c))
export const OverviewSearchProfileControl = () => import('../../components/overview/SearchProfileControl.vue' /* webpackChunkName: "components/overview-search-profile-control" */).then(c => wrapFunctional(c.default || c))
export const OverviewTenderCard = () => import('../../components/overview/TenderCard.vue' /* webpackChunkName: "components/overview-tender-card" */).then(c => wrapFunctional(c.default || c))
export const OverviewTenderCardView = () => import('../../components/overview/TenderCardView.vue' /* webpackChunkName: "components/overview-tender-card-view" */).then(c => wrapFunctional(c.default || c))
export const OverviewTenderListView = () => import('../../components/overview/TenderListView.vue' /* webpackChunkName: "components/overview-tender-list-view" */).then(c => wrapFunctional(c.default || c))
export const OverviewTenderRow = () => import('../../components/overview/TenderRow.vue' /* webpackChunkName: "components/overview-tender-row" */).then(c => wrapFunctional(c.default || c))
export const PaymentsCardInput = () => import('../../components/payments/CardInput.vue' /* webpackChunkName: "components/payments-card-input" */).then(c => wrapFunctional(c.default || c))
export const SkeletonListItem = () => import('../../components/skeleton/ListItem.vue' /* webpackChunkName: "components/skeleton-list-item" */).then(c => wrapFunctional(c.default || c))
export const SkeletonMessisStatistic = () => import('../../components/skeleton/MessisStatistic.vue' /* webpackChunkName: "components/skeleton-messis-statistic" */).then(c => wrapFunctional(c.default || c))
export const SkeletonPieChart = () => import('../../components/skeleton/PieChart.vue' /* webpackChunkName: "components/skeleton-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const SkeletonTenderCard = () => import('../../components/skeleton/TenderCard.vue' /* webpackChunkName: "components/skeleton-tender-card" */).then(c => wrapFunctional(c.default || c))
export const TreeCPVCodeTree = () => import('../../components/tree/CPVCodeTree.vue' /* webpackChunkName: "components/tree-c-p-v-code-tree" */).then(c => wrapFunctional(c.default || c))
export const TreeNUTSCodeTree = () => import('../../components/tree/NUTSCodeTree.vue' /* webpackChunkName: "components/tree-n-u-t-s-code-tree" */).then(c => wrapFunctional(c.default || c))
export const TreeNaceCodeTree = () => import('../../components/tree/NaceCodeTree.vue' /* webpackChunkName: "components/tree-nace-code-tree" */).then(c => wrapFunctional(c.default || c))
export const TreeOrganizationTree = () => import('../../components/tree/OrganizationTree.vue' /* webpackChunkName: "components/tree-organization-tree" */).then(c => wrapFunctional(c.default || c))
export const TreeSBICodeTree = () => import('../../components/tree/SBICodeTree.vue' /* webpackChunkName: "components/tree-s-b-i-code-tree" */).then(c => wrapFunctional(c.default || c))
export const TreeSourceTree = () => import('../../components/tree/SourceTree.vue' /* webpackChunkName: "components/tree-source-tree" */).then(c => wrapFunctional(c.default || c))
export const TreeStructure = () => import('../../components/tree/TreeStructure.vue' /* webpackChunkName: "components/tree-structure" */).then(c => wrapFunctional(c.default || c))
export const DetailPlanningCalendar = () => import('../../components/detail/planning/calendar.vue' /* webpackChunkName: "components/detail-planning-calendar" */).then(c => wrapFunctional(c.default || c))
export const DetailPlanningTimeline = () => import('../../components/detail/planning/timeline.vue' /* webpackChunkName: "components/detail-planning-timeline" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSigningChainEditor = () => import('../../components/document-vault/signing/ChainEditor.vue' /* webpackChunkName: "components/document-vault-signing-chain-editor" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSigningChainState = () => import('../../components/document-vault/signing/ChainState.vue' /* webpackChunkName: "components/document-vault-signing-chain-state" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSigningChainlink = () => import('../../components/document-vault/signing/Chainlink.vue' /* webpackChunkName: "components/document-vault-signing-chainlink" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSigningOverviewWindow = () => import('../../components/document-vault/signing/OverviewWindow.vue' /* webpackChunkName: "components/document-vault-signing-overview-window" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSigningUserChain = () => import('../../components/document-vault/signing/UserChain.vue' /* webpackChunkName: "components/document-vault-signing-user-chain" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSigningUserInput = () => import('../../components/document-vault/signing/UserInput.vue' /* webpackChunkName: "components/document-vault-signing-user-input" */).then(c => wrapFunctional(c.default || c))
export const DocumentVaultSigningWindow = () => import('../../components/document-vault/signing/Window.vue' /* webpackChunkName: "components/document-vault-signing-window" */).then(c => wrapFunctional(c.default || c))
export const EvaluationReportFeedbackPage = () => import('../../components/evaluation/report/FeedbackPage.vue' /* webpackChunkName: "components/evaluation-report-feedback-page" */).then(c => wrapFunctional(c.default || c))
export const EvaluationReportHeader = () => import('../../components/evaluation/report/Header.vue' /* webpackChunkName: "components/evaluation-report-header" */).then(c => wrapFunctional(c.default || c))
export const EvaluationReportInfoPage = () => import('../../components/evaluation/report/InfoPage.vue' /* webpackChunkName: "components/evaluation-report-info-page" */).then(c => wrapFunctional(c.default || c))
export const EvaluationReportPerformanceMetricPage = () => import('../../components/evaluation/report/PerformanceMetricPage.vue' /* webpackChunkName: "components/evaluation-report-performance-metric-page" */).then(c => wrapFunctional(c.default || c))
export const EvaluationReportPricekeysPage = () => import('../../components/evaluation/report/PricekeysPage.vue' /* webpackChunkName: "components/evaluation-report-pricekeys-page" */).then(c => wrapFunctional(c.default || c))
export const EvaluationReportRankingPage = () => import('../../components/evaluation/report/RankingPage.vue' /* webpackChunkName: "components/evaluation-report-ranking-page" */).then(c => wrapFunctional(c.default || c))
export const EvaluationReportWritersPage = () => import('../../components/evaluation/report/WritersPage.vue' /* webpackChunkName: "components/evaluation-report-writers-page" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsPerformanceOverviewKpi = () => import('../../components/market-insights/PerformanceOverview/kpi.vue' /* webpackChunkName: "components/market-insights-performance-overview-kpi" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsPerformanceOverviewKpiSkeleton = () => import('../../components/market-insights/PerformanceOverview/kpiSkeleton.vue' /* webpackChunkName: "components/market-insights-performance-overview-kpi-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsSearchBuyer = () => import('../../components/market-insights/Search/Buyer.vue' /* webpackChunkName: "components/market-insights-search-buyer" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsSearchSeller = () => import('../../components/market-insights/Search/Seller.vue' /* webpackChunkName: "components/market-insights-search-seller" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsSearchWriter = () => import('../../components/market-insights/Search/Writer.vue' /* webpackChunkName: "components/market-insights-search-writer" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBidBreakdownCard = () => import('../../components/market-insights/card/BidBreakdownCard.vue' /* webpackChunkName: "components/market-insights-card-bid-breakdown-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerAwardCriteriaRatioCard = () => import('../../components/market-insights/card/BuyerAwardCriteriaRatioCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-award-criteria-ratio-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerCoreNumbersCard = () => import('../../components/market-insights/card/BuyerCoreNumbersCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-core-numbers-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerEstimatedValueDistributionCard = () => import('../../components/market-insights/card/BuyerEstimatedValueDistributionCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-estimated-value-distribution-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerExpectedTenderDistributionCard = () => import('../../components/market-insights/card/BuyerExpectedTenderDistributionCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-expected-tender-distribution-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerProcedureRatioCard = () => import('../../components/market-insights/card/BuyerProcedureRatioCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-procedure-ratio-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerQualityAwardCriteriaCard = () => import('../../components/market-insights/card/BuyerQualityAwardCriteriaCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-quality-award-criteria-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerRelationCard = () => import('../../components/market-insights/card/BuyerRelationCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-relation-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerSellerWinCountCard = () => import('../../components/market-insights/card/BuyerSellerWinCountCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-seller-win-count-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerTenderCountCard = () => import('../../components/market-insights/card/BuyerTenderCountCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-tender-count-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerTypeRatioCard = () => import('../../components/market-insights/card/BuyerTypeRatioCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-type-ratio-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardBuyerWriterRelationCard = () => import('../../components/market-insights/card/BuyerWriterRelationCard.vue' /* webpackChunkName: "components/market-insights-card-buyer-writer-relation-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardContractValueDistributionCard = () => import('../../components/market-insights/card/ContractValueDistributionCard.vue' /* webpackChunkName: "components/market-insights-card-contract-value-distribution-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardCustomerSellersCard = () => import('../../components/market-insights/card/CustomerSellersCard.vue' /* webpackChunkName: "components/market-insights-card-customer-sellers-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardExpectedTenderCard = () => import('../../components/market-insights/card/ExpectedTenderCard.vue' /* webpackChunkName: "components/market-insights-card-expected-tender-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardPerformanceCategoryFeedbackCard = () => import('../../components/market-insights/card/PerformanceCategoryFeedbackCard.vue' /* webpackChunkName: "components/market-insights-card-performance-category-feedback-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardPerformanceCategoryScoreCard = () => import('../../components/market-insights/card/PerformanceCategoryScoreCard.vue' /* webpackChunkName: "components/market-insights-card-performance-category-score-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardPerformanceCategoryTenderScoreCard = () => import('../../components/market-insights/card/PerformanceCategoryTenderScoreCard.vue' /* webpackChunkName: "components/market-insights-card-performance-category-tender-score-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardPerformanceHistoryCard = () => import('../../components/market-insights/card/PerformanceHistoryCard.vue' /* webpackChunkName: "components/market-insights-card-performance-history-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardPerformanceOverviewCard = () => import('../../components/market-insights/card/PerformanceOverviewCard.vue' /* webpackChunkName: "components/market-insights-card-performance-overview-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardSellerAwardCriteriaRatioCard = () => import('../../components/market-insights/card/SellerAwardCriteriaRatioCard.vue' /* webpackChunkName: "components/market-insights-card-seller-award-criteria-ratio-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardSellerQualityCriterionPrevalenceCard = () => import('../../components/market-insights/card/SellerQualityCriterionPrevalenceCard.vue' /* webpackChunkName: "components/market-insights-card-seller-quality-criterion-prevalence-card" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCardWonTendersPerYearCard = () => import('../../components/market-insights/card/WonTendersPerYearCard.vue' /* webpackChunkName: "components/market-insights-card-won-tenders-per-year-card" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartBidEvaluation = () => import('../../components/my-tenders/part/BidEvaluation.vue' /* webpackChunkName: "components/my-tenders-part-bid-evaluation" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartBidNoBidMatrix = () => import('../../components/my-tenders/part/BidNoBidMatrix.vue' /* webpackChunkName: "components/my-tenders-part-bid-no-bid-matrix" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartBuyer = () => import('../../components/my-tenders/part/Buyer.vue' /* webpackChunkName: "components/my-tenders-part-buyer" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartCustomField = () => import('../../components/my-tenders/part/CustomField.vue' /* webpackChunkName: "components/my-tenders-part-custom-field" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartNextPlanningItem = () => import('../../components/my-tenders/part/NextPlanningItem.vue' /* webpackChunkName: "components/my-tenders-part-next-planning-item" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartProjectMembers = () => import('../../components/my-tenders/part/ProjectMembers.vue' /* webpackChunkName: "components/my-tenders-part-project-members" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartTaskCount = () => import('../../components/my-tenders/part/TaskCount.vue' /* webpackChunkName: "components/my-tenders-part-task-count" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartTotalDocumentCount = () => import('../../components/my-tenders/part/TotalDocumentCount.vue' /* webpackChunkName: "components/my-tenders-part-total-document-count" */).then(c => wrapFunctional(c.default || c))
export const MyTendersPartValue = () => import('../../components/my-tenders/part/Value.vue' /* webpackChunkName: "components/my-tenders-part-value" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersAdvancedFilter = () => import('../../components/overview/filters/AdvancedFilter.vue' /* webpackChunkName: "components/overview-filters-advanced-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersAdvisingOrganizationFilter = () => import('../../components/overview/filters/AdvisingOrganizationFilter.vue' /* webpackChunkName: "components/overview-filters-advising-organization-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersAwardCriteriaFilter = () => import('../../components/overview/filters/AwardCriteriaFilter.vue' /* webpackChunkName: "components/overview-filters-award-criteria-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersAwardedOrganizationFilter = () => import('../../components/overview/filters/AwardedOrganizationFilter.vue' /* webpackChunkName: "components/overview-filters-awarded-organization-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersBuyingOrganizationFilter = () => import('../../components/overview/filters/BuyingOrganizationFilter.vue' /* webpackChunkName: "components/overview-filters-buying-organization-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersCpvFilter = () => import('../../components/overview/filters/CpvFilter.vue' /* webpackChunkName: "components/overview-filters-cpv-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersDateRangeFilter = () => import('../../components/overview/filters/DateRangeFilter.vue' /* webpackChunkName: "components/overview-filters-date-range-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersKeywordsFilter = () => import('../../components/overview/filters/KeywordsFilter.vue' /* webpackChunkName: "components/overview-filters-keywords-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersNutsFilter = () => import('../../components/overview/filters/NutsFilter.vue' /* webpackChunkName: "components/overview-filters-nuts-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersPhaseFilter = () => import('../../components/overview/filters/PhaseFilter.vue' /* webpackChunkName: "components/overview-filters-phase-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProcedureFilter = () => import('../../components/overview/filters/ProcedureFilter.vue' /* webpackChunkName: "components/overview-filters-procedure-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersSearchWordsFilter = () => import('../../components/overview/filters/SearchWordsFilter.vue' /* webpackChunkName: "components/overview-filters-search-words-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersSectorFilter = () => import('../../components/overview/filters/SectorFilter.vue' /* webpackChunkName: "components/overview-filters-sector-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersSellingOrganizationFilter = () => import('../../components/overview/filters/SellingOrganizationFilter.vue' /* webpackChunkName: "components/overview-filters-selling-organization-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersStatusFilter = () => import('../../components/overview/filters/StatusFilter.vue' /* webpackChunkName: "components/overview-filters-status-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersStoppedProcedureFilter = () => import('../../components/overview/filters/StoppedProcedureFilter.vue' /* webpackChunkName: "components/overview-filters-stopped-procedure-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersTenderSourceFilter = () => import('../../components/overview/filters/TenderSourceFilter.vue' /* webpackChunkName: "components/overview-filters-tender-source-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersTenderTypeFilter = () => import('../../components/overview/filters/TenderTypeFilter.vue' /* webpackChunkName: "components/overview-filters-tender-type-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersValueFilter = () => import('../../components/overview/filters/ValueFilter.vue' /* webpackChunkName: "components/overview-filters-value-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersYearFilter = () => import('../../components/overview/filters/YearFilter.vue' /* webpackChunkName: "components/overview-filters-year-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalAwardCriteriaFilter = () => import('../../components/overview/filters/Proposal/AwardCriteriaFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-award-criteria-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalCompetitorFilter = () => import('../../components/overview/filters/Proposal/CompetitorFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-competitor-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalContextFilter = () => import('../../components/overview/filters/Proposal/ContextFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-context-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalDeadlineFilter = () => import('../../components/overview/filters/Proposal/DeadlineFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-deadline-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalEvaluationTagFilter = () => import('../../components/overview/filters/Proposal/EvaluationTagFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-evaluation-tag-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalIncludeSuggestionFilter = () => import('../../components/overview/filters/Proposal/IncludeSuggestionFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-include-suggestion-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalIntermediariesFilter = () => import('../../components/overview/filters/Proposal/IntermediariesFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-intermediaries-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalPerformanceFilter = () => import('../../components/overview/filters/Proposal/PerformanceFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-performance-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalWonLostFilter = () => import('../../components/overview/filters/Proposal/WonLostFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-won-lost-filter" */).then(c => wrapFunctional(c.default || c))
export const OverviewFiltersProposalBuyersFilter = () => import('../../components/overview/filters/Proposal/buyersFilter.vue' /* webpackChunkName: "components/overview-filters-proposal-buyers-filter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
