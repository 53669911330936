import Filter from './Filter';

export default class TenderTypeFilter extends Filter {
  get name () {
    return 'tenderType';
  }

  get label () {
    return 'Aanbestedingstype';
  }

  get UI () {
    return 'tenderType';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  count (options) {
    if (options.length > 0) { return options.length; }
  }

  isActive (options) {
    return options.length > 0;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = searchprofile.tenderTypes?.length > 0;
    filter.options = searchprofile.tenderTypes?.map(x => x.type) ?? [];
  }

  toSearchProfile (options) {
    return {
      tenderTypes: options.map(x => ({ type: x }))
    };
  }
}
