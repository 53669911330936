import { DateTime } from 'luxon';
import Filter from './Filter';

export default class FirstExpirationDateFilter extends Filter {
  get name () {
    return 'firstExpirationDate';
  }

  get label () {
    return 'Vervaldatum';
  }

  get UI () {
    return {
      name: 'DateRange',
      props: {
        label: 'eerst volgende vervaldatum van een overeenkomst'
      }
    };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  isActive (options) {
    return options.some(x => x !== null);
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (
      searchprofile.minimumFirstExpirationDate ||
      searchprofile.maximumFirstExpirationDate
    ) {
      filter.options = [
        searchprofile.minimumFirstExpirationDate,
        searchprofile.maximumFirstExpirationDate
      ];
      filter.active = true;
    }
  }

  toSearchProfile (options) {
    if (options.some(x => x !== null)) {
      const minimum = DateTime.fromISO(options[0]).toUTC();
      const maximum = DateTime.fromISO(options[1]).toUTC();

      return {
        minimumFirstExpirationDate: minimum.isValid
          ? minimum.toString()
          : null,
        maximumFirstExpirationDate: maximum.isValid
          ? maximum.toString()
          : null
      };
    }
  }
}
