// import all files ending in Filter.js in this directory using webpack
// https://webpack.js.org/guides/dependency-management/#require-context
const requireFilter = require.context('.', false, /\.\/.+Filter\.js$/);
// eslint-disable-next-line new-cap
const filters = requireFilter.keys().map(file => new (requireFilter(file).default)());

export function getFilter (name) {
  const filter = filters.find(x => x.name === name);

  if (!filter) {
    throw new Error(`${name} filter not found.`);
  }
  return filter;
}

export default filters;
