import Filter from './Filter';

export default class NUTSCodeFilter extends Filter {
  get name () {
    return 'NUTS';
  }

  get label () {
    return 'Locatie';
  }

  get UI () {
    return { name: 'NUTS', resizable: true };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  count (options) {
    return options.length;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.nutsLocations && searchprofile.nutsLocations.length > 0) {
      filter.options = searchprofile.nutsLocations.map(x => x.nutsCode);
      filter.active = true;
    }
  }

  toSearchProfile (options) {
    if (options.length > 0) {
      return {
        nutsLocations: options.map(x => ({ nutsCode: x }))
      };
    }
  }
}
