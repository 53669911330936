
import navbar from '@/components/Navbar.vue';
import modal from '@/components/modal.vue';
import HolidayMixin from '@/mixins/HolidayMixin.js';

export default {
  components: {
    navbar,
    modal
  },
  mixins: [HolidayMixin]
};
