/* eslint-disable no-console */
import https from 'https';
import { resolveTransitionHooks } from '@vue/runtime-dom';

// Backend is self signed certificate, all programs reject this by default.
export default function (context) {
  const { $axios, $toast } = context;
  console.assert('$toast' in context);
  $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });

  $axios.onRequest((request) => {
    if (process.server) {
      console.log(`➡️ [${request.method.toString().toUpperCase()}] ${request.url}`);
    }
  });

  $axios.onResponse((response) => {
    if (process.server) {
      console.log(`⬅ [${response.request.method}] [${response.status}] ${response.request.path}`);
    }
  });

  $axios.onError((err) => {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    if (process.server) {
      console.log(`[${err.response && err.response.status}] [${err.response.request.method}] ${err.response && err.response.request.path}`);
      console.error(err.response && err.response.data);
      return;
    }

    if ($axios.isCancel(err) || err.response.status === 404) {
      return;
    }

    $toast({
      error: true,
      icon: 'alert-circle',
      title: `Unhandled request-error on '${err.config?.url ?? 'unknown'}'`,
      subtitle: `Request failed with status ${err.response?.statusText} (${err.response?.status})`
    });
  });
}
