import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=2d55f86a&scoped=true"
import script from "./Navbar.vue?vue&type=script&lang=js"
export * from "./Navbar.vue?vue&type=script&lang=js"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=2d55f86a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d55f86a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AlturaToggleButton: require('/app/node_modules/@altura/components/components/ToggleButton.vue').default,AlturaDropdown: require('/app/node_modules/@altura/components/components/Dropdown.vue').default,AlturaButton: require('/app/node_modules/@altura/components/components/Button.vue').default,AlturaLogo: require('/app/node_modules/@altura/components/components/Logo.vue').default,AlturaProfilePicture: require('/app/node_modules/@altura/components/components/ProfilePicture.vue').default,ProfileMenu: require('/app/components/ProfileMenu.vue').default,AlturaContainer: require('/app/node_modules/@altura/components/components/Container.vue').default,AlturaListItem: require('/app/node_modules/@altura/components/components/list/ListItem.vue').default,AlturaList: require('/app/node_modules/@altura/components/components/list/List.vue').default,AlturaMenuItem: require('/app/node_modules/@altura/components/components/menu/MenuItem.vue').default,AlturaSeparator: require('/app/node_modules/@altura/components/components/Separator.vue').default,AlturaContextMenu: require('/app/node_modules/@altura/components/components/menu/ContextMenu.vue').default})
