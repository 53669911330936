import { DateTime } from 'luxon';
import { holidays } from '@/assets/holidays/holidays.json';

export default {
  computed: {
    holidayLogo () {
      if (!this.currentHoliday) {
        return undefined;
      }
      return require(`~/assets/holidays/${this.currentHoliday.name}.svg?inline`);
    },

    currentHoliday () {
      const date = DateTime.now();
      let dayInYear = date.ordinal;

      if (date.isInLeapYear && dayInYear >= 60) { dayInYear -= 1; }

      return holidays.find(x => x.days.includes(dayInYear));
    }
  },

  provide () {
    return {
      holidayLogo: this.holidayLogo,
      holiday: this.currentHoliday
    };
  },

  mounted () {
    if (!this.currentHoliday) { return; }

    document.body.classList.add(this.currentHoliday.name);
    for (const [key, color] of Object.entries(this.currentHoliday.colors)) { document.body.style.setProperty(`--${key}`, color); }
  },

  head () {
    if (this.currentHoliday) {
      return {
        meta: [
          { name: 'theme-color', content: Object.values(this.currentHoliday.colors)[0].toLowerCase() }
        ]
      };
    } else {
      return {
        meta: [
          { name: 'theme-color', content: '#2f0db9' }
        ]
      };
    }
  }
};
