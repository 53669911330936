import Filter from './Filter';

export default class ProposalWonLostFilter extends Filter {
  get name () {
    return 'proposal-won-lost';
  }

  get label () {
    return 'Gewonnen/verloren';
  }

  get UI () {
    return { name: 'proposal-won-lost' };
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = ['won', 'lost', 'pending'];
  }

  count (options) {
    if (options.length > 0) { return options.length; }
  }

  isActive (options) {
    return options.length !== 3;
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.wins) {
      filter.options = searchprofile.wins;
      filter.active = this.isActive(filter.options);
    }
  }

  toSearchProfile (options) {
    if (this.isActive(options)) {
      return {
        wins: {
          won: options.includes('won'),
          lost: options.includes('lost'),
          pending: options.includes('pending')
        }
      };
    } else {
      return {};
    };
  }
}
