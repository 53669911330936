import createFilters from '@/mixins/FilterStore';

const filters = createFilters('proposalSearch', [
  'proposal-award-criteria',
  'proposal-won-lost',
  'proposal-include-suggestion',
  'proposal-context',
  'proposal-performance',
  'proposal-buyers',
  'proposal-intermediaries',
  'proposal-competitor',
  'proposal-evaluation-Tag',
  'proposal-deadline'
], { default: { proposals: [], total: 0 } });

export const state = () => ({
  ...filters.state,
  proposals: [],
  count: 0,
  page: 1,
  maxPages: 1,
  time: 0,
  sort: undefined,
  loading: false
});

export const mutations = {
  ...filters.mutations,
  setTime (state, time) {
    state.time = time;
  },

  setSort (state, sort) {
    state.sort = sort;
  },

  clearProposals (state) {
    state.page = 0;
    state.proposals = [];
    state.count = 0;
  },

  setPage (state, number) {
    if (number === undefined) {
      state.page++;
      return;
    }

    state.page = number;
  },

  setMaxPage (state, number) {
    state.maxPages = number;
  },

  setProposals (state, { proposal, count }) {
    state.proposals = proposal;

    if (count !== undefined) {
      if (count === 0) {
        state.maxPages = 1;
        state.count = 0;
        return;
      }

      state.maxPages = Math.ceil(count / 25);
      state.count = count;
    }
  },

  setLoading (state, loading) {
    state.loading = loading;
  },

  reset (state, fetch = true) {
    this.commit('proposalSearch/clearProposals');
    this.commit('proposalSearch/resetFilters');
  }
};

export const getters = {
  ...filters.getters,

  parameters (state) {
    const params = {};

    if (state.sort) {
      params.sortingType = state.sort;
    }

    return params;
  }
};

export const actions = {
  ...filters.actions
};
