export const state = () => ({
  evaluatedTenders: [],
  buyers: [],
  evaluatedOrganizations: [],
  buyersEnd: false,
  evaluatedOrganizationsEnd: false
});

export const mutations = {
  setBuyers (state, buyers) {
    state.buyers = buyers;
    state.buyersEnd = false;
  },

  addBuyers (state, buyers) {
    state.buyers.push(...buyers);
    state.buyersEnd = buyers.length === 0;
  },

  clearBuyers (state) {
    state.buyers = [];
    state.buyersEnd = false;
  },

  setSellers (state, evaluatedOrganizations) {
    state.evaluatedOrganizations = evaluatedOrganizations;
    state.evaluatedOrganizationsEnd = false;
  },

  addSellers (state, evaluatedOrganizations) {
    state.evaluatedOrganizations.push(...evaluatedOrganizations);
    state.evaluatedOrganizationsEnd = evaluatedOrganizations.length === 0;
  },

  clearSellers (state) {
    state.evaluatedOrganizations = [];
    state.evaluatedOrganizationsEnd = false;
  },

  setEvaluatedTenders (state, evaluatedTenders) {
    state.evaluatedTenders = evaluatedTenders;
  }
};

export const actions = {
  async getEvaluatedTenders ({ commit }) {
    const evaluatedTenders = await this.$axios.$get('/bidevaluation/evaluated-projects');
    commit('setEvaluatedTenders', evaluatedTenders);
  }
};
