
import modal from '@/components/modal.vue';
import HolidayMixin from '@/mixins/HolidayMixin.js';

export default {
  components: {
    modal
  },

  mixins: [HolidayMixin],

  head () {
    const standalone = this.$route.query.standalone === 'true';
    return {
      titleTemplate: standalone ? '%s' : '%s | Altura'
    };
  },

  computed: {
    platformSettings () {
      return this.$store.state.platformSettings;
    }
  },

  mounted () {
    this.loadPlatformSettings();
  },

  methods: {
    startEggHunt () {
      window.startEggHunt();
    },
    async loadPlatformSettings () {
      try {
        const platformSettings = await this.$axios.$get(
          'https://strapi.altura.io/platform-settings',
          {
            transformRequest: (data, headers) => {
              delete headers.Authorization;
              delete headers.common.Authorization;
              delete headers.teamId;
              return data;
            }
          }
        );

        const allowedURLs = ['/maintenance', '/login/callback/aad'];

        if (platformSettings.blockLogin && !allowedURLs.includes(this.$route.path) && !this.$route.query.bypass) {
          this.$router.replace('/maintenance');
        }

        this.$store.commit('setPlatformSettings', platformSettings);
      } catch {}
    }
  }
};
