export default ({ app }, inject) => {
  inject('formatUrl', (url) => {
    if (url === undefined) {
      return '#';
    }

    if (!/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }

    return url;
  });
};
