import { render, staticRenderFns } from "./default.vue?vue&type=template&id=39b7e046&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=39b7e046&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b7e046",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/app/components/Navbar.vue').default,AlturaBanner: require('/app/node_modules/@altura/components/components/Banner.vue').default,Modal: require('/app/components/modal.vue').default,ProModal: require('/app/components/ProModal.vue').default,AlturaLogo: require('/app/node_modules/@altura/components/components/Logo.vue').default,AlturaContainer: require('/app/node_modules/@altura/components/components/Container.vue').default})
