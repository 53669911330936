import Filter from './Filter';

export default class ProcedureFilter extends Filter {
  get name () {
    return 'procedure';
  }

  get label () {
    return 'Procedures';
  }

  get UI () {
    return 'procedure';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  count (options) {
    if (options.length > 0) { return options.length; }
  }

  isActive (options) {
    return options.length > 0;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = searchprofile.procedureTypes?.length > 0;
    filter.options = searchprofile.procedureTypes?.map(x => x.type) ?? [];
  }

  toSearchProfile (options) {
    return {
      procedureTypes: options.map(x => ({ type: x }))
    };
  }
}
