import Filter from './Filter';

export default class StatusFilter extends Filter {
  get name () {
    return 'status';
  }

  get label () {
    return 'Status';
  }

  get UI () {
    return 'status';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = ['Active', 'Closed', 'Expected'];
  }

  count (options) {
    if (options.length !== 3) { return options.length; }
  }

  isActive (options) {
    return options.length !== 3;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = searchprofile.tenderStatusCollection && searchprofile.tenderStatusCollection.length !== 3;
    filter.options = searchprofile.tenderStatusCollection?.map(x => x.status) ?? [];
  }

  toSearchProfile (options) {
    return {
      tenderStatusCollection: options.map(x => ({ status: x }))
    };
  }
}
