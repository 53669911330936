import Filter from './Filter';

export default class ValueFilter extends Filter {
  get name () {
    return 'value';
  }

  get label () {
    return 'Waarde';
  }

  get UI () {
    return 'value';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = {
      min: undefined,
      max: undefined
    };
  }

  updateFromSearchProfile (searchprofile, filter) {
    if (searchprofile.size && searchprofile.size.minSize != null && searchprofile.size.maxSize != null) {
      filter.options = {
        min: searchprofile.size.minSize,
        max: searchprofile.size.maxSize
      };
      filter.active = true;
    } else {
      filter.options = {
        min: undefined,
        max: undefined
      };

      filter.active = false;
    }
  }

  toSearchProfile (options) {
    const min = Number(options.min ?? 0);
    const max = Number(options.max) ?? -1;

    if (min >= 0 && max > min) {
      return {
        size: {
          minSize: Number(min),
          maxSize: Number(max)
        }
      };
    }
  }
}
