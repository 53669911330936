
import modal from '@/components/modal.vue';
import navbar from '@/components/Navbar.vue';

export default {
  components: {
    navbar,
    modal
  }
};
