

export default {
  data () {
    return {
      showSwitchTeam: false
    };
  },

  computed: {
    sortedTeams () {
      return [
        ...this.$user.teams.filter(x => x.isPinned),
        ...this.$user.teams.filter(x => !x.isPinned)
      ];
    }
  },

  methods: {
    async changeTeam (team) {
      this.showSwitchTeam = false;

      await this.$user.changeTeam(team.id);
    },

    logout () {
      this.$store.commit('reset');
      navigator.credentials.preventSilentAccess();
      this.$auth.logout();
      this.$router.replace('/login');
    }
  }
};
