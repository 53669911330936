export default function ({ route, $auth, $user, redirect, next }) {
  if (route.meta.length < 1 || !route.meta[0].feature) {
    return;
  }

  const features = route.meta[0].feature.split('|');

  if ($auth.loggedIn && features.some(f => $user.hasFeature(f))) {
    return;
  }

  redirect('/');
}
