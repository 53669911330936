import linkify from 'linkify-html';

export default function richText (text, tabindex = 0) {
  const linkified = linkify(text, {
    attributes: {
      tabindex: tabindex.toString()
    },
    target: {
      url: '_blank'
    }
  });

  const cleaned = linkified.replace(/(<script)\s*[^>]*>([^<]*<\/script>)?/gi, '(script removed)');

  return cleaned.trim();
}
