import { DateTime } from 'luxon';
import Vue from 'vue';
import richText from '~/mixins/richText';

Vue.filter('formatNumber', (val, decimalPlaces = 2) => {
  const numberFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlaces
  });
  if (val === undefined || val == null || Number.isNaN(val)) {
    return 'Onbekend';
  }

  return numberFormatter.format(val);
});

Vue.filter('formatPrice', (val, currency, alwaysShowCurrency) => {
  const currencySigns = {
    EUR: '€',
    USD: '$',
    GBP: '£'
  };
  if (val === undefined || val === null) {
    if (alwaysShowCurrency) {
      return `${currencySigns[currency] || ''} Onbekend`.trim();
    }

    return 'Onbekend';
  }

  if (currency) {
    const numberFormatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
      compactDisplay: 'short',
      trailingZeroDisplay: 'stripIfInteger'
    });

    return numberFormatter.format(val).replace(/,00$/, ',–');
  }

  const [numbers, decimals] = val.toFixed(2).split('.');

  let result = '';

  for (let i = numbers.length; i > 0; i--) {
    if (i % 3 === 0 && i < numbers.length) {
      result += '.';
    }

    result += numbers[numbers.length - i];
  }

  if (decimals && decimals !== '00') {
    result += ',' + decimals;
  } else {
    result += ',–';
  }

  return result;
});

Vue.filter('upperCase', val => val?.toUpperCase());

Vue.filter('lowerCase', val => val?.toLowerCase());

Vue.filter('capitalize', (val) => {
  if (val === undefined || val === null) {
    return '';
  }

  return val[0].toUpperCase() + val.substring(1);
});

Vue.filter('trim', (val) => {
  if (val === undefined || val === null) {
    return '';
  }

  const string = val.toString();
  const trimmed = string.trim();
  return trimmed;
});

Vue.filter('formatDateTime', (val, timeZone) => {
  let date = DateTime.fromISO(val);

  if (date.valueOf() === 0 || val === '0001-01-01T00:00:00' || !date.isValid) {
    return 'Onbekend';
  }

  if (timeZone) {
    date = date.setZone(timeZone);
  }

  return date.toFormat('d MMM yyyy, HH:mm', { locale: 'nl' });
});

Vue.filter('formatDate', (val, format, timezone) => {
  if (!val) {
    return 'Onbekend';
  }
  if (!format) {
    format = 'iso';
  }

  let date = null;

  if (format === 'iso') {
    date = DateTime.fromISO(val);
  } else if (format === 'epoch') {
    date = DateTime.fromMillis(val);
  }

  if (timezone) {
    date = date.setZone(timezone);
  }

  if (
    date.valueOf() === 0 ||
    val.toString().startsWith('0001-01-01') ||
    !date.isValid
  ) {
    console.warn(date.invalidExplanation);
    return 'Onbekend';
  }

  return date.toFormat('d MMM yyyy', { locale: 'nl' });
});

Vue.filter('formatMS', (value) => {
  if (value < 1000) {
    return Math.round(value) + 'ms';
  }
  if (value < 60000) {
    return (value / 1000).toFixed(1).replace(/[,.]0/g, '') + 's';
  }
  if (value < 3600000) {
    return (value / 60000).toFixed(1).replace(/[,.]0/g, '') + 'min';
  }
  if (value < 86400000) {
    return (value / 3600000).toFixed(1).replace(/[,.]0/g, '') + ' uur';
  }
});

Vue.filter('address', (val) => {
  if (!val) {
    return 'Geen adres';
  }
  if (val.street === undefined && val.city === undefined) {
    return 'Geen adres';
  }

  if (val.street) {
    return (
      `${val.street} ${
        (val.houseNumber?.toString() ?? '') +
        (val.houseNumberAddition?.toString() ?? '')
      }`.trim() + `, ${val.postalCode} ${val?.city ?? ''}`
    );
  }

  return val.city?.trim() ?? 'Geen adres';
});

Vue.filter('list', (val) => {
  if (!Array.isArray(val)) {
    return val;
  }
  const formatter = new Intl.ListFormat('nl');

  return formatter.format(val);
});

Vue.filter('ranking', (val) => {
  if (!val) {
    return '-';
  }

  if (val === 1 || val >= 20 || val === 8) {
    return `${val}ste`;
  }

  return `${val}de`;
});

Vue.filter('rich-text', richText);

Vue.filter('formatFileSize', formatByteSize);

/**
 * @param {Number} size
 */
export function formatByteSize (size) {
  const numberFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'decimal',
    maximumFractionDigits: 0
  });
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (Number.isNaN(Number(size))) {
    return '-';
  }

  if (size < 1) {
    return numberFormatter.format(size) + units[0];
  }

  if (size === Number.MAX_SAFE_INTEGER) {
    return 'imposible bytesize';
  }

  const exponent = Math.min(units.length - 1, Math.floor(Math.log10(size) / 3));

  size /= 1000 ** exponent;
  size = size.toPrecision(3);

  const sizeString = numberFormatter.format(size);
  const unit = units[exponent];

  return sizeString + unit;
}
