import { getMatchedComponents } from './utils.js';

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto';
    });

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual';
    });
  }
}

export default function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false;

  // if no children detected and scrollToTop is not explicitly disabled
  const Pages = getMatchedComponents(to);
  if (
    Pages.length < 2 &&
    Pages.every(Page => Page.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 };
  } else if (Pages.some(Page => Page.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 };
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition;
  }

  const nuxt = window.$nuxt;

  // triggerScroll is only fired when a new component is loaded
  if (to.path === from.path && to.hash !== from.hash) {
    nuxt.$nextTick(() => nuxt.$emit('triggerScroll'));
  }

  return new Promise((resolve) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });

    // wait for the out transition to complete (if necessary)
    nuxt.$off('triggerScrollComponentLoaded');
    nuxt.$once('triggerScrollComponentLoaded', () => {
      position = savedPosition;
      resolve(position);
    });
  });
}
