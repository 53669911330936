export default function ({ $config }) {
  // eslint-disable-next-line no-console
  console.log(`%c

       ▓▓▓▓▓         ▓▓▓▓▒
      ▓▓▓▓▓▓▒       ▓▓▓▓▓     ▒▓▓▓▒
     ▒▓▓▓▓▓▓▓       ▓▓▓▓▓  ▒▒▓▓▓▓▓▒                                         ▒▒▒
     ▓▓▓▒▓▓▓▓▓      ▓▓▓▓▓  ▒▓▓▓▓▓▓▓▓▓▒  ▓▓▓▓▓    ▒▓▓▓▓    ▓▓▓▓  ▓▓▓▓▓   ▒▓▓▓▓▓▓▓▓▓▓▒
    ▓▓▓▓  ▓▓▓▓▒     ▓▓▓▓▓    ▓▓▓▓▓▒     ▓▓▓▓▓    ▒▓▓▓▓    ▓▓▓▓▓▓▓▓▓▓▒  ▓▓▓▓▒   ▒▓▓▓▓▓
   ▒▓▓▓▓  ▒▓▓▓▓     ▓▓▓▓▓    ▓▓▓▓▓▒     ▓▓▓▓▓    ▒▓▓▓▓    ▓▓▓▓▓▒      ▓▓▓▓▓     ▓▓▓▓▓
   ▓▓▓▓▒   ▓▓▓▓▓    ▓▓▓▓▓    ▓▓▓▓▓▒     ▓▓▓▓▓    ▒▓▓▓▓    ▓▓▓▓▒       ▓▓▓▓▓     ▓▓▓▓▓
  ▒▓▓▓▓   ▒▓▓▓▓▓▒   ▓▓▓▓▓    ▓▓▓▓▓▒     ▓▓▓▓▓    ▒▓▓▓▓    ▓▓▓▓▒       ▓▓▓▓▓     ▓▓▓▓▓
 ▒▓▓▓▓▒▒▓▓▓▓▓▓▓▓▓   ▓▓▓▓▓    ▓▓▓▓▓▒     ▓▓▓▓▓    ▓▓▓▓▓    ▓▓▓▓▒       ▓▓▓▓▓▒    ▓▓▓▓▓    ▒
 ▓▓▓▓▓▓▓▓▓   ▓▓▓▓▒  ▓▓▓▓▓    ▒▓▓▓▓▓▓▓▓  ▓▓▓▓▓▓▓▓▓▒▓▓▓▓    ▓▓▓▓▒        ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
%c
Version (${$config.BUILD_ID ?? 'Onbekend'})\n%c
Is front-end helemaal je ding? https://alturabv.recruitee.com/`,
  'font-family: monospace; font-size: 0.75rem; color: #2f0db9; line-height: 1rem; white-space: pre;', 'color: #777; font-family: sans-serif; text-align: right;', 'font-size: 1rem; font-family: sans-serif;');
}
