import { DateTime } from 'luxon';
import Filter from './Filter';

export default class MoreFilter extends Filter {
  get name () {
    return 'more';
  }

  get label () {
    return 'Meer filters';
  }

  get UI () {
    return {
      name: 'more',
      dialog: true
    };
  }

  get pro () {
    return true;
  }

  constructor () {
    super();

    this.options = {
      phases: [],
      procedures: [],
      criteria: {
        min: undefined,
        max: undefined
      },
      searchWords: [],
      sellingOrganizations: [],
      buyingOrganizations: [],
      tenderSources: [],
      types: [],
      publicationDate: [null, null],
      endDate: [null, null]
    };
  }

  isActive (options) {
    if (options?.phases?.length > 0) {
      return true;
    }

    if (options?.procedures?.length > 0) {
      return true;
    }

    if (
      options?.criteria?.min !== null ||
      options?.criteria?.max !== null
    ) {
      return true;
    }

    if (options?.searchWords?.length > 0) {
      return true;
    }

    if (options?.buyingOrganizations?.length > 0) {
      return true;
    }
    if (options?.sellingOrganizations?.length > 0) {
      return true;
    }

    if (options?.searchWords?.length > 0) {
      return true;
    }

    if (options?.types?.length > 0) {
      return true;
    }

    if (
      options.publicationDate.length === 2 &&
      !options.publicationDate.some(x => !x)
    ) {
      return true;
    }

    if (
      options.endDate.length === 2 &&
      !options.endDate.some(x => !x)
    ) {
      return true;
    }

    return false;
  }

  count (options) {
    let count = 0;

    if (options.phases.length > 0) {
      count++;
    }

    if (options.searchWords.length > 0) {
      count++;
    }

    if (options.procedures.length > 0) {
      count++;
    }

    if (options.buyingOrganizations.length > 0) {
      count++;
    }
    if (options.sellingOrganizations.length > 0) {
      count++;
    }

    if (options.tenderSources.length > 0) {
      count++;
    }

    if (options.types.length > 0) {
      count++;
    }

    if (
      options.criteria.min !== undefined &&
      options.criteria.max !== undefined
    ) {
      count++;
    }

    if (options.publicationDate.some(x => Object.keys(x).length > 0)) {
      count++;
    }

    if (options.endDate.some(x => Object.keys(x).length > 0)) {
      count++;
    }

    return count;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = false;

    if (searchprofile.searchWords && searchprofile.searchWords.length) {
      filter.options.searchWords = searchprofile.searchWords.map(x => x.word);
      filter.active = true;
    }

    if (searchprofile.procedureTypes && searchprofile.procedureTypes.length) {
      filter.options.procedures = searchprofile.procedureTypes.map(
        x => x.type
      );
      filter.active = true;
    }

    if (searchprofile.tenderTypes && searchprofile.tenderTypes.length) {
      filter.options.types = searchprofile.tenderTypes.map(x => x.type);
      filter.active = true;
    }

    if (searchprofile.tenderPhases && searchprofile.tenderPhases.length) {
      filter.options.phases = searchprofile.tenderPhases.map(
        x => x.tenderPhase
      );
      filter.active = true;
    }

    if (
      searchprofile.buyingOrganizations &&
      searchprofile.buyingOrganizations.length
    ) {
      filter.options.buyingOrganizations = searchprofile.buyingOrganizations;
      filter.active = true;
    }

    if (
      searchprofile.sellingOrganizations &&
      searchprofile.sellingOrganizations.length
    ) {
      filter.options.sellingOrganizations = searchprofile.sellingOrganizations;
      filter.active = true;
    }

    if (searchprofile.tenderSources && searchprofile.tenderSources.length) {
      filter.options.tenderSources = searchprofile.tenderSources.map(
        x => x.tenderSource
      );
      filter.active = true;
    }

    if (
      searchprofile.minimumPublicationDate ||
      searchprofile.maximumPublicationDate
    ) {
      filter.options.publicationDate = [
        searchprofile.minimumPublicationDate,
        searchprofile.maximumPublicationDate
      ];
      filter.active = true;
    }

    if (searchprofile.minimumEndDate || searchprofile.maximumEndDate) {
      filter.options.endDate = [
        searchprofile.minimumEndDate,
        searchprofile.maximumEndDate
      ];
      filter.active = true;
    }

    if (
      searchprofile.awardCriteriaCollection &&
      searchprofile.awardCriteriaCollection.length > 0
    ) {
      const quality = searchprofile.awardCriteriaCollection[0];
      filter.options.criteria = {
        min: quality.minPercentage,
        max: quality.maxPercentage
      };
      filter.active = true;
    }
  }

  fromData (data) {
    const options = super.fromData(data);

    options.searchWords = [...new Set(data.searchWords)];

    return options;
  }

  toSearchProfile (options) {
    const filters = {};
    if (options.procedures) {
      filters.procedureTypes = options.procedures.map(x => ({ type: x }));
    }

    if (options.types) {
      filters.tenderTypes = options.types.map(x => ({ type: x }));
    }

    if (options.searchWords) {
      filters.searchWords = [...new Set(options.searchWords)].map(x => ({
        word: x
      }));
    }

    if (options.phases) {
      filters.tenderPhases = options.phases.map(x => ({ tenderPhase: x }));
    }

    if (options.buyingOrganizations) {
      filters.buyingOrganizations = options.buyingOrganizations.map(x => ({
        id: x
      }));
    }

    if (options.sellingOrganizations) {
      filters.sellingOrganizations = options.sellingOrganizations.map(x => ({
        id: x
      }));
    }

    if (options.tenderSources) {
      filters.tenderSources = options.tenderSources.map(x => ({
        tenderSource: x
      }));
    }

    if ((options.criteria.min >= 0 || options.criteria.max >= 0) && (options.criteria.min !== null && options.criteria.max !== null)) {
      filters.awardCriteriaCollection = [
        {
          minPercentage: options.criteria.min ?? undefined,
          maxPercentage: options.criteria.max ?? undefined
        }
      ];
    }

    if (options.publicationDate.some(x => x !== null)) {
      const minimum = DateTime.fromISO(options.publicationDate[0]).toUTC();
      const maximum = DateTime.fromISO(options.publicationDate[1]).toUTC();

      filters.minimumPublicationDate = minimum.isValid
        ? minimum.toString()
        : null;
      filters.maximumPublicationDate = maximum.isValid
        ? maximum.toString()
        : null;
    }

    if (options.endDate.some(x => x !== null)) {
      const minimum = DateTime.fromISO(options.endDate[0]).toUTC();
      const maximum = DateTime.fromISO(options.endDate[1]).toUTC();

      filters.minimumEndDate = minimum.isValid ? minimum.toString() : null;
      filters.maximumEndDate = maximum.isValid ? maximum.toString() : null;
    }

    return filters;
  }
}
