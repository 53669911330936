import Filter from './Filter';

export default class TenderSourceFilter extends Filter {
  get name () {
    return 'tenderSource';
  }

  get label () {
    return 'Publicatiebron';
  }

  get UI () {
    return 'tenderSource';
  }

  get pro () {
    return false;
  }

  constructor () {
    super();
    this.options = [];
  }

  count (options) {
    if (options.length > 0) { return options.length; }
  }

  isActive (options) {
    return options.length > 0;
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.active = searchprofile.tenderSources?.length > 0;
    filter.options = searchprofile.tenderSources?.map(x => x.tenderSource) ?? [];
  }

  toSearchProfile (options) {
    return {
      tenderSources: options.map(x => ({ tenderSource: x }))
    };
  }
}
