import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0b390336 = () => interopDefault(import('../pages/invite/index.vue' /* webpackChunkName: "pages/invite/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6bd9eff0 = () => interopDefault(import('../pages/market-overview.vue' /* webpackChunkName: "pages/market-overview" */))
const _587929eb = () => interopDefault(import('../pages/market-overview/market.vue' /* webpackChunkName: "pages/market-overview/market" */))
const _2616ca68 = () => interopDefault(import('../pages/market-overview/planning.vue' /* webpackChunkName: "pages/market-overview/planning" */))
const _2560483c = () => interopDefault(import('../pages/market-overview/strategy.vue' /* webpackChunkName: "pages/market-overview/strategy" */))
const _721ab500 = () => interopDefault(import('../pages/market-overview/tenders.vue' /* webpackChunkName: "pages/market-overview/tenders" */))
const _020ec273 = () => interopDefault(import('../pages/market-overview/index.vue' /* webpackChunkName: "pages/market-overview/index" */))
const _1e03ec88 = () => interopDefault(import('../pages/municipal-budgets/index.vue' /* webpackChunkName: "pages/municipal-budgets/index" */))
const _65f8d7ae = () => interopDefault(import('../pages/organizations/index.vue' /* webpackChunkName: "pages/organizations/index" */))
const _7b9700f2 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _17f55fa8 = () => interopDefault(import('../pages/proposal-search/index.vue' /* webpackChunkName: "pages/proposal-search/index" */))
const _2c20a960 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _0f04757e = () => interopDefault(import('../pages/tender/index.vue' /* webpackChunkName: "pages/tender/index" */))
const _6eed0a1a = () => interopDefault(import('../pages/tiers/index.vue' /* webpackChunkName: "pages/tiers/index" */))
const _1a5551b9 = () => interopDefault(import('../pages/analyse/market-insights/index.vue' /* webpackChunkName: "pages/analyse/market-insights/index" */))
const _6b690dfb = () => interopDefault(import('../pages/collaborate/bid-no-bid/index.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/index" */))
const _c356c60c = () => interopDefault(import('../pages/collaborate/document-vault/index.vue' /* webpackChunkName: "pages/collaborate/document-vault/index" */))
const _66a0d4f8 = () => interopDefault(import('../pages/collaborate/my-tenders/index.vue' /* webpackChunkName: "pages/collaborate/my-tenders/index" */))
const _2e464758 = () => interopDefault(import('../pages/invite/accept.vue' /* webpackChunkName: "pages/invite/accept" */))
const _5001a336 = () => interopDefault(import('../pages/login/forgot-password.vue' /* webpackChunkName: "pages/login/forgot-password" */))
const _60f4872d = () => interopDefault(import('../pages/login/team.vue' /* webpackChunkName: "pages/login/team" */))
const _c1d7851c = () => interopDefault(import('../pages/onboarding/confirmation/index.vue' /* webpackChunkName: "pages/onboarding/confirmation/index" */))
const _9b99c44e = () => interopDefault(import('../pages/organizations/buyers.vue' /* webpackChunkName: "pages/organizations/buyers" */))
const _5684712a = () => interopDefault(import('../pages/organizations/sellers.vue' /* webpackChunkName: "pages/organizations/sellers" */))
const _0d55f9ed = () => interopDefault(import('../pages/search-profile/create.vue' /* webpackChunkName: "pages/search-profile/create" */))
const _7ba0feb3 = () => interopDefault(import('../pages/settings/bid-no-bid.vue' /* webpackChunkName: "pages/settings/bid-no-bid" */))
const _cf174dcc = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _2080d346 = () => interopDefault(import('../pages/settings/notification.vue' /* webpackChunkName: "pages/settings/notification" */))
const _22ae96a5 = () => interopDefault(import('../pages/settings/organization.vue' /* webpackChunkName: "pages/settings/organization" */))
const _7a94ad0d = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _262a2727 = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _70f3fd8f = () => interopDefault(import('../pages/settings/subscription.vue' /* webpackChunkName: "pages/settings/subscription" */))
const _4cd96992 = () => interopDefault(import('../pages/tender/redesign.vue' /* webpackChunkName: "pages/tender/redesign" */))
const _49cb5a75 = () => interopDefault(import('../pages/tiers/pro.vue' /* webpackChunkName: "pages/tiers/pro" */))
const _0370ebc9 = () => interopDefault(import('../pages/collaborate/document-vault/fileView.vue' /* webpackChunkName: "pages/collaborate/document-vault/fileView" */))
const _5270368a = () => interopDefault(import('../pages/collaborate/document-vault/folderView.vue' /* webpackChunkName: "pages/collaborate/document-vault/folderView" */))
const _d5a3d674 = () => interopDefault(import('../pages/collaborate/my-tenders/create.vue' /* webpackChunkName: "pages/collaborate/my-tenders/create" */))
const _5d8a3b96 = () => interopDefault(import('../pages/collaborate/signing/wet.vue' /* webpackChunkName: "pages/collaborate/signing/wet" */))
const _25e181ca = () => interopDefault(import('../pages/login/callback/aad.vue' /* webpackChunkName: "pages/login/callback/aad" */))
const _7efa52ee = () => interopDefault(import('../pages/collaborate/signing/dry/success.vue' /* webpackChunkName: "pages/collaborate/signing/dry/success" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4f7c7d87 = () => interopDefault(import('../pages/collaborate/bid-no-bid/copy/_id.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/copy/_id" */))
const _26a082ca = () => interopDefault(import('../pages/collaborate/bid-no-bid/copy/_id/index.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/copy/_id/index" */))
const _7b694330 = () => interopDefault(import('../pages/collaborate/bid-no-bid/copy/_id/_categoryID.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/copy/_id/_categoryID" */))
const _41dff66f = () => interopDefault(import('../pages/collaborate/bid-no-bid/result/_id.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/result/_id" */))
const _4d42e3ca = () => interopDefault(import('../pages/collaborate/bid-no-bid/use/_id.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/use/_id" */))
const _567315c4 = () => interopDefault(import('../pages/collaborate/bid-no-bid/use/_id/_categoryID.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/use/_id/_categoryID" */))
const _3972b55d = () => interopDefault(import('../pages/collaborate/my-tenders/report/_reportID.vue' /* webpackChunkName: "pages/collaborate/my-tenders/report/_reportID" */))
const _2c485323 = () => interopDefault(import('../pages/collaborate/bid-no-bid/_id.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/_id" */))
const _6a769934 = () => interopDefault(import('../pages/collaborate/bid-no-bid/_id/index.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/_id/index" */))
const _4814e268 = () => interopDefault(import('../pages/collaborate/bid-no-bid/_id/_categoryID.vue' /* webpackChunkName: "pages/collaborate/bid-no-bid/_id/_categoryID" */))
const _3e5563fc = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/index.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/index" */))
const _11de77d0 = () => interopDefault(import('../pages/expected/tender/_tenderID.vue' /* webpackChunkName: "pages/expected/tender/_tenderID" */))
const _dc342e28 = () => interopDefault(import('../pages/onboarding/confirmation/_confirmID.vue' /* webpackChunkName: "pages/onboarding/confirmation/_confirmID" */))
const _1dba7819 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate" */))
const _d2753e8a = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/1.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/1" */))
const _41e23105 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/10.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/10" */))
const _d2590f88 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/2.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/2" */))
const _d23ce086 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/3.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/3" */))
const _d220b184 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/4.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/4" */))
const _d2048282 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/5.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/5" */))
const _d1e85380 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/6.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/6" */))
const _d1cc247e = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/7.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/7" */))
const _d1aff57c = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/8.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/8" */))
const _d193c67a = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/9.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/9" */))
const _d76e7048 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/evaluate/index.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/evaluate/index" */))
const _3a2e4080 = () => interopDefault(import('../pages/collaborate/my-tenders/_projectID/report/_bidEvaluationId.vue' /* webpackChunkName: "pages/collaborate/my-tenders/_projectID/report/_bidEvaluationId" */))
const _275cae07 = () => interopDefault(import('../pages/collaborate/document-vault/_.vue' /* webpackChunkName: "pages/collaborate/document-vault/_" */))
const _8342db6e = () => interopDefault(import('../pages/create-account/_platform.vue' /* webpackChunkName: "pages/create-account/_platform" */))
const _41f1ea47 = () => interopDefault(import('../pages/organizations/_organizationID/index.vue' /* webpackChunkName: "pages/organizations/_organizationID/index" */))
const _6bb33000 = () => interopDefault(import('../pages/tender/_tenderID/index.vue' /* webpackChunkName: "pages/tender/_tenderID/index" */))
const _2f0ab6fd = () => interopDefault(import('../pages/organizations/_organizationID/preview.vue' /* webpackChunkName: "pages/organizations/_organizationID/preview" */))
const _729a355b = () => interopDefault(import('../pages/tender/_tenderID/evaluate.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate" */))
const _a9dcc2e8 = () => interopDefault(import('../pages/tender/_tenderID/evaluate/advisor.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/advisor" */))
const _07b22533 = () => interopDefault(import('../pages/tender/_tenderID/evaluate/award-criteria.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/award-criteria" */))
const _0bcfe1f3 = () => interopDefault(import('../pages/tender/_tenderID/evaluate/competitors.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/competitors" */))
const _735f6de9 = () => interopDefault(import('../pages/tender/_tenderID/evaluate/feedback.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/feedback" */))
const _595c5cfc = () => interopDefault(import('../pages/tender/_tenderID/evaluate/performance.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/performance" */))
const _5720fed6 = () => interopDefault(import('../pages/tender/_tenderID/evaluate/price.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/price" */))
const _5df04e09 = () => interopDefault(import('../pages/tender/_tenderID/evaluate/pricekeys.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/pricekeys" */))
const _3af44da8 = () => interopDefault(import('../pages/tender/_tenderID/evaluate/writers.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/writers" */))
const _23020b9e = () => interopDefault(import('../pages/tender/_tenderID/evaluate/index.vue' /* webpackChunkName: "pages/tender/_tenderID/evaluate/index" */))
const _2ec932e8 = () => interopDefault(import('../pages/tender/_tenderID/oembed.vue' /* webpackChunkName: "pages/tender/_tenderID/oembed" */))
const _7c16aa76 = () => interopDefault(import('../pages/tender/_tenderID/preview.vue' /* webpackChunkName: "pages/tender/_tenderID/preview" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/invite",
    component: _0b390336,
    pathToRegexpOptions: {"strict":true},
    name: "invite"
  }, {
    path: "/login",
    component: _cad79e9c,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance"
  }, {
    path: "/market-overview",
    component: _6bd9eff0,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "market",
      component: _587929eb,
      pathToRegexpOptions: {"strict":true},
      name: "market-overview-market"
    }, {
      path: "planning",
      component: _2616ca68,
      pathToRegexpOptions: {"strict":true},
      name: "market-overview-planning"
    }, {
      path: "strategy",
      component: _2560483c,
      pathToRegexpOptions: {"strict":true},
      name: "market-overview-strategy"
    }, {
      path: "tenders",
      component: _721ab500,
      pathToRegexpOptions: {"strict":true},
      name: "market-overview-tenders"
    }, {
      path: "",
      component: _020ec273,
      pathToRegexpOptions: {"strict":true},
      name: "market-overview"
    }]
  }, {
    path: "/municipal-budgets",
    component: _1e03ec88,
    pathToRegexpOptions: {"strict":true},
    name: "municipal-budgets"
  }, {
    path: "/organizations",
    component: _65f8d7ae,
    pathToRegexpOptions: {"strict":true},
    name: "organizations"
  }, {
    path: "/password-reset",
    component: _7b9700f2,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset"
  }, {
    path: "/proposal-search",
    component: _17f55fa8,
    pathToRegexpOptions: {"strict":true},
    name: "proposal-search"
  }, {
    path: "/settings",
    component: _2c20a960,
    pathToRegexpOptions: {"strict":true},
    name: "settings"
  }, {
    path: "/tender",
    component: _0f04757e,
    pathToRegexpOptions: {"strict":true},
    name: "tender"
  }, {
    path: "/tiers",
    component: _6eed0a1a,
    pathToRegexpOptions: {"strict":true},
    name: "tiers"
  }, {
    path: "/analyse/market-insights",
    component: _1a5551b9,
    pathToRegexpOptions: {"strict":true},
    name: "analyse-market-insights"
  }, {
    path: "/collaborate/bid-no-bid",
    component: _6b690dfb,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-bid-no-bid"
  }, {
    path: "/collaborate/document-vault",
    component: _c356c60c,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-document-vault"
  }, {
    path: "/collaborate/my-tenders",
    component: _66a0d4f8,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-my-tenders"
  }, {
    path: "/invite/accept",
    component: _2e464758,
    pathToRegexpOptions: {"strict":true},
    name: "invite-accept"
  }, {
    path: "/login/forgot-password",
    component: _5001a336,
    pathToRegexpOptions: {"strict":true},
    name: "login-forgot-password"
  }, {
    path: "/login/team",
    component: _60f4872d,
    pathToRegexpOptions: {"strict":true},
    name: "login-team"
  }, {
    path: "/onboarding/confirmation",
    component: _c1d7851c,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding-confirmation"
  }, {
    path: "/organizations/buyers",
    component: _9b99c44e,
    pathToRegexpOptions: {"strict":true},
    name: "organizations-buyers"
  }, {
    path: "/organizations/sellers",
    component: _5684712a,
    pathToRegexpOptions: {"strict":true},
    name: "organizations-sellers"
  }, {
    path: "/search-profile/create",
    component: _0d55f9ed,
    pathToRegexpOptions: {"strict":true},
    name: "search-profile-create"
  }, {
    path: "/settings/bid-no-bid",
    component: _7ba0feb3,
    pathToRegexpOptions: {"strict":true},
    name: "settings-bid-no-bid"
  }, {
    path: "/settings/email",
    component: _cf174dcc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email"
  }, {
    path: "/settings/notification",
    component: _2080d346,
    pathToRegexpOptions: {"strict":true},
    name: "settings-notification"
  }, {
    path: "/settings/organization",
    component: _22ae96a5,
    pathToRegexpOptions: {"strict":true},
    name: "settings-organization"
  }, {
    path: "/settings/password",
    component: _7a94ad0d,
    pathToRegexpOptions: {"strict":true},
    name: "settings-password"
  }, {
    path: "/settings/profile",
    component: _262a2727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile"
  }, {
    path: "/settings/subscription",
    component: _70f3fd8f,
    pathToRegexpOptions: {"strict":true},
    name: "settings-subscription"
  }, {
    path: "/tender/redesign",
    component: _4cd96992,
    pathToRegexpOptions: {"strict":true},
    name: "tender-redesign"
  }, {
    path: "/tiers/pro",
    component: _49cb5a75,
    pathToRegexpOptions: {"strict":true},
    name: "tiers-pro"
  }, {
    path: "/collaborate/document-vault/fileView",
    component: _0370ebc9,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-document-vault-fileView"
  }, {
    path: "/collaborate/document-vault/folderView",
    component: _5270368a,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-document-vault-folderView"
  }, {
    path: "/collaborate/my-tenders/create",
    component: _d5a3d674,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-my-tenders-create"
  }, {
    path: "/collaborate/signing/wet",
    component: _5d8a3b96,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-signing-wet"
  }, {
    path: "/login/callback/aad",
    component: _25e181ca,
    pathToRegexpOptions: {"strict":true},
    name: "login-callback-aad"
  }, {
    path: "/collaborate/signing/dry/success",
    component: _7efa52ee,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-signing-dry-success"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/collaborate/bid-no-bid/copy/:id?",
    component: _4f7c7d87,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _26a082ca,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-bid-no-bid-copy-id"
    }, {
      path: ":categoryID",
      component: _7b694330,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-bid-no-bid-copy-id-categoryID"
    }]
  }, {
    path: "/collaborate/bid-no-bid/result/:id?",
    component: _41dff66f,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-bid-no-bid-result-id"
  }, {
    path: "/collaborate/bid-no-bid/use/:id?",
    component: _4d42e3ca,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-bid-no-bid-use-id",
    children: [{
      path: ":categoryID?",
      component: _567315c4,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-bid-no-bid-use-id-categoryID"
    }]
  }, {
    path: "/collaborate/my-tenders/report/:reportID",
    component: _3972b55d,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-my-tenders-report-reportID"
  }, {
    path: "/collaborate/bid-no-bid/:id?",
    component: _2c485323,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6a769934,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-bid-no-bid-id"
    }, {
      path: ":categoryID",
      component: _4814e268,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-bid-no-bid-id-categoryID"
    }]
  }, {
    path: "/collaborate/my-tenders/:projectID",
    component: _3e5563fc,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-my-tenders-projectID"
  }, {
    path: "/expected/tender/:tenderID?",
    component: _11de77d0,
    pathToRegexpOptions: {"strict":true},
    name: "expected-tender-tenderID"
  }, {
    path: "/onboarding/confirmation/:confirmID",
    component: _dc342e28,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding-confirmation-confirmID"
  }, {
    path: "/collaborate/my-tenders/:projectID?/evaluate",
    component: _1dba7819,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "1",
      component: _d2753e8a,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-1"
    }, {
      path: "10",
      component: _41e23105,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-10"
    }, {
      path: "2",
      component: _d2590f88,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-2"
    }, {
      path: "3",
      component: _d23ce086,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-3"
    }, {
      path: "4",
      component: _d220b184,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-4"
    }, {
      path: "5",
      component: _d2048282,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-5"
    }, {
      path: "6",
      component: _d1e85380,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-6"
    }, {
      path: "7",
      component: _d1cc247e,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-7"
    }, {
      path: "8",
      component: _d1aff57c,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-8"
    }, {
      path: "9",
      component: _d193c67a,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate-9"
    }, {
      path: "",
      component: _d76e7048,
      pathToRegexpOptions: {"strict":true},
      name: "collaborate-my-tenders-projectID-evaluate"
    }]
  }, {
    path: "/collaborate/my-tenders/:projectID?/report/:bidEvaluationId",
    component: _3a2e4080,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-my-tenders-projectID-report-bidEvaluationId"
  }, {
    path: "/collaborate/document-vault/*",
    component: _275cae07,
    pathToRegexpOptions: {"strict":true},
    name: "collaborate-document-vault-all"
  }, {
    path: "/create-account/:platform?",
    component: _8342db6e,
    pathToRegexpOptions: {"strict":true},
    name: "create-account-platform"
  }, {
    path: "/organizations/:organizationID",
    component: _41f1ea47,
    pathToRegexpOptions: {"strict":true},
    name: "organizations-organizationID"
  }, {
    path: "/tender/:tenderID",
    component: _6bb33000,
    pathToRegexpOptions: {"strict":true},
    name: "tender-tenderID"
  }, {
    path: "/organizations/:organizationID/preview",
    component: _2f0ab6fd,
    pathToRegexpOptions: {"strict":true},
    name: "organizations-organizationID-preview"
  }, {
    path: "/tender/:tenderID/evaluate",
    component: _729a355b,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "advisor",
      component: _a9dcc2e8,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-advisor"
    }, {
      path: "award-criteria",
      component: _07b22533,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-award-criteria"
    }, {
      path: "competitors",
      component: _0bcfe1f3,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-competitors"
    }, {
      path: "feedback",
      component: _735f6de9,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-feedback"
    }, {
      path: "performance",
      component: _595c5cfc,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-performance"
    }, {
      path: "price",
      component: _5720fed6,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-price"
    }, {
      path: "pricekeys",
      component: _5df04e09,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-pricekeys"
    }, {
      path: "writers",
      component: _3af44da8,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate-writers"
    }, {
      path: "",
      component: _23020b9e,
      pathToRegexpOptions: {"strict":true},
      name: "tender-tenderID-evaluate"
    }]
  }, {
    path: "/tender/:tenderID/oembed",
    component: _2ec932e8,
    pathToRegexpOptions: {"strict":true},
    name: "tender-tenderID-oembed"
  }, {
    path: "/tender/:tenderID/preview",
    component: _7c16aa76,
    pathToRegexpOptions: {"strict":true},
    name: "tender-tenderID-preview"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
