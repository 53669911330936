export default ({ store }, inject) => {
  inject('modal', {
    showModal (options) {
      store.commit('modal/showModal', options);
    },
    closeModal () {
      store.commit('modal/closeModal');
    },

    confirm ({ title, body, positiveLabel, negativeLabel, destructive }) {
      return new Promise((resolve) => {
        this.showModal({
          title,
          body,
          compact: true,
          destructive,
          positiveButton: {
            label: positiveLabel ?? 'Ja',

            action: () => {
              resolve(true);
              this.closeModal();
            }
          },
          negativeButton: {
            label: negativeLabel ?? 'Nee',
            action: () => {
              resolve(false);
              this.closeModal();
            }
          }
        });
      });
    }
  });
};
