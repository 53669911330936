import Vue from 'vue';

export default (
  { store, $toast, $auth, $cookies, route, $axios, redirect },
  inject
) => {
  const platform = Vue.observable({ platform: 'None' });
  let currentTeam = Vue.observable(null);
  const teamId = route.query.team_id ?? $cookies.get('team_id');

  const auth = route?.matched?.[0]?.components?.default?.options?.auth;
  let changeTeamTarget = null;

  if (process.browser) {
    changeTeamTarget = new EventTarget();
  }

  function getCurrentTeam () {
    if ($auth.loggedIn) {
      if ($auth.user.teams.length === 1) {
        currentTeam = $auth.user.teams[0];
      } else {
        currentTeam = $auth.user.teams.find(x => x.id === teamId);
      }

      if (currentTeam) {
        $axios.defaults.headers.teamId = currentTeam.id;
        platform.platform = currentTeam.platforms[0];
        return;
      }
    }
    const allowedRoutes = ['/login', '/login/team'];
    if ((!currentTeam && (auth === false || auth?.includes?.('guest'))) || allowedRoutes.includes(route.path)) {
      return;
    }

    redirect('/login/team?redirect=' + encodeURIComponent(route.path));
  }

  getCurrentTeam();

  inject('user', {
    /**
     * @returns {AlturaTeam | false}
     */
    team: currentTeam,

    /**
     * @returns {AlturaTeamSettings | false}
     */
    get settings () {
      return this.team.settings;
    },

    get teams () {
      if (!$auth.loggedIn || !$auth.user) { return []; }

      const teams = [...$auth.user.teams].sort((a, b) =>
        a.company.localeCompare(b.company)
      );

      if (process.browser) {
        const pins = JSON.parse(localStorage.getItem('pinned_teams') ?? '[]');

        return teams.map(x => ({ ...x, isPinned: pins.includes(x.id) }));
      }

      return teams.map(x => ({ ...x, isPinned: false }));
    },

    get platform () {
      return platform.platform;
    },

    get teamMates () {
      if (!store.state.User.teamMates[this.team.id]) {
        store.dispatch('User/loadTeamMates');
      }

      return store.state.User.teamMates[this.team.id];
    },

    get permissions () {
      if (!$auth.loggedIn || !$auth.user) {
        return [];
      }

      return $auth.user.permissions[this.team.id].flatMap(permission =>
        permission.permissions.map(p => p.name)
      );
    },

    changeTeam (teamId, silent = false, refetch = true) {
      if (this.team && this.team.id === teamId) {
        return;
      }

      const team = this.teams.find(x => x.id === teamId);
      if (!team) {
        console.warn(`Team ${teamId} doesn't exist in your teams.`);
        return;
      }
      platform.platform = team.platforms[0];

      $axios.defaults.headers.teamId = teamId;

      Vue.set(this, 'team', team);

      if (process.browser) {
        changeTeamTarget.dispatchEvent(new CustomEvent('team-change', { detail: team }));
      }

      store.dispatch('onTeamSwitch');

      store.dispatch('User/loadTeamMates');

      $cookies.set('team_id', teamId.toLowerCase(), {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        sameSite: 'strict'
      });

      if (refetch) {
        window.$nuxt.refresh();
      }

      if (silent) { return; }

      $toast({
        title: 'Je bent van team veranderd.',
        subtitle: `Je team is nu ingesteld op ${this.team.company}.`,
        icon: 'star'
      });
    },

    /**
     * Check if the current team has a role.
     * @param {("Billing" | "User" | "Administrator" | "Advisor")} role
     */
    hasRole (role) {
      if (!this.team || !$auth.user) {
        return false;
      }

      return $auth.user.roles[this.team.id].some(x => x.name === role);
    },

    /**
     * Check if the current team has a feature.
     * @param {("Tenders" | "ExpectedTenders" | "Sellers" | "Buyers" | "MunicipalBudgets" | "MyTenders" | "BidEvaluations" | "MarketInsights" | "MoreFilters")} feature
     */
    hasFeature (feature) {
      if (!this.team || this.team.features.length === 0) {
        return false;
      }

      return this.team.features.some(x => x.access === feature);
    },

    hasPermission (permission) {
      return this.permissions.includes(permission);
    },

    onTeamChange (handler) {
      if (!changeTeamTarget) {
        return;
      }
      changeTeamTarget.addEventListener('team-change', handler);
    },

    removeOnTeamChange (handler) {
      if (!changeTeamTarget) {
        return;
      }
      changeTeamTarget.removeEventListener('team-change', handler);
    }
  });
};
