import Filter from './Filter.js';

export default class AdvancedFilter extends Filter {
  get name () {
    return 'advanced';
  }

  get UI () {
    return {
      name: 'advanced',
      icon: 'settings',
      dialog: true
    };
  }

  get label () {
    return 'Geavanceerde filteropties';
  }

  get position () {
    return 'end';
  }

  get pro () {
    return false;
  }

  isActive (options) {
    return (
      options.searchWordsInternalLogicGate === 'AND' ||
      options.cpvExternalLogicGate === 'OR' ||
      options.tenderSourceInternalLogicGate === 'AND' ||
      options.documentFilterInternalLogicGate === 'AND' ||
      options.includeDocumentFilterUnknownTenders ||
      options.includeNutsUnknownTenders ||
      options.includeUnknownCPVTenders
    );
  }

  constructor () {
    super();
    this.options = {
      searchWordsInternalLogicGate: 'OR',
      cpvExternalLogicGate: 'AND',
      tenderSourceInternalLogicGate: 'OR',
      documentFilterInternalLogicGate: 'OR',
      includeDocumentFilterUnknownTenders: false,
      includeNutsUnknownTenders: false,
      includeUnknownCPVTenders: false
    };
  }

  updateFromSearchProfile (searchprofile, filter) {
    filter.options = {
      searchWordsInternalLogicGate:
        searchprofile.searchWordsInternalLogicGate ?? 'OR',
      cpvExternalLogicGate: searchprofile.cpvExternalLogicGate ?? 'AND',
      tenderSourceInternalLogicGate:
        searchprofile.tenderSourceInternalLogicGate ?? 'OR',
      documentFilterInternalLogicGate: searchprofile.documentFilterInternalLogicGate ?? 'OR',
      includeDocumentFilterUnknownTenders: searchprofile.includeDocumentFilterUnknownTenders ?? false,
      includeNutsUnknownTenders: searchprofile.includeNutsUnknownTenders ?? false,
      includeUnknownCPVTenders: searchprofile.includeUnknownCPVTenders ?? false
    };

    filter.active = this.isActive(filter.options);
  }

  fromData (data) {
    const options = super.fromData(data);

    options.includeDocumentFilterUnknownTenders = data.includeDocumentFilterUnknownTenders === 'true';
    options.includeNutsUnknownTenders = data.includeNutsUnknownTenders === 'true';
    options.includeUnknownCPVTenders = data.includeUnknownCPVTenders === 'true';

    return options;
  }

  toSearchProfile (options) {
    return options;
  }
}
